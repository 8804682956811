@import "../../../../../../../styles/media-mixin";

.wrapper {
  transition: 0.15s max-height ease-in-out, 0.15s height ease-in-out;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 428px;
  grid-gap: 8px;
  padding: 20px;

  @include media("<desktop-xlg") {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media("<tablet-lg") {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }

  @include media("<phone-lg") {
    padding: 12px;
  }
}