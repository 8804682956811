.coverWrapper {
  position: relative;
  min-width: 324px;
  margin: 0 -24px;
  will-change: transform, margin;
  transition: 0.15s transform ease-in-out, 0.25s margin ease-in-out;
  transform: scale(0.85);
  border-radius: 4px;
  cursor: pointer;

  &:after {
    content: "";
    will-change: bottom;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--dark-1-50);
    transition: 0.25s bottom ease-in-out;
    pointer-events: none;
  }

  &:first-child {
    margin-left: 50vw;
  }

  &:last-child {
    margin-right: 50vw;
  }
}

.coverImageWrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.coverImage {
  height: 576px;
  width: auto;
  object-fit: cover;
}

.storiesModalItem {
  position: relative;
  min-width: 324px;
  margin: 0 -24px;
  will-change: transform, margin;
  transition: 0.15s transform ease-in-out, 0.25s margin ease-in-out;
  transform: scale(0.85);
  border-radius: 4px;

  &:after {
    content: "";
    will-change: bottom;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--dark-1-50);
    transition: 0.25s bottom ease-in-out;
    pointer-events: none;
  }

  &:first-child {
    margin-left: 50vw;
  }

  &:last-child {
    margin-right: 50vw;
  }
}

.active {
  transform: scale(1);
  margin: 0 24px;

  &:after {
    bottom: unset;
  }

  &:hover {
    .leftArrow {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    .rightArrow {
      opacity: 1;
      transform: translate(50%, -50%) scale(1);
    }
  }
}

.leftArrow,
.rightArrow {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-1);
  border: 1px solid var(--dark-2);
  color: var(--grey-4);
  cursor: pointer;
  transform: scale(0);
  opacity: 0;
  z-index: 3;
  will-change: background-color, color, opacity, transform;
  transition: 0.25s background-color ease-in-out, 0.25s color ease-in-out, 0.25s opacity ease-in-out, 0.1s transform ease-in-out;

  &:hover {
    background-color: var(--dark-2);
    color: var(--white);
  }
}

.leftArrow {
  left: 0;
  transform: translate(-50%, -50%) scale(0);
}

.rightArrow {
  right: 0;
  transform: translate(50%, -50%) scale(0);
}

.storyTitleWrapper {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  background-color: var(--white);
  border-radius: 12px;
  transition: 0.25s background-color ease-in-out, 0.25s transform ease-in-out;
}

.storyTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-1);
  max-width: 220px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
}

.playButton {
  flex-shrink: 0;
  background-color: var(--dark-1);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: relative;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent var(--white);
  transition: 0.25s border-color ease-in-out;
  margin-left: 1px;
}

.storyAuthor {
  position: absolute;
  left: 12px;
  bottom: 12px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-1);
  background-color: var(--white);
  border-radius: 12px;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
}