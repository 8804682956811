@import "../../../styles/media-mixin.scss";

.container {
  position: relative;
  padding: 24px;
  border: 1px solid var(--dark-3);
  border-radius: 4px;
  display: grid;
  grid-template-areas:
          "title title title socials"
          "actions actions team team";
  grid-gap: 80px;
  background-color: var(--dark-1);
  background-size: cover;
  background-repeat: no-repeat;

  @include media("<tablet-lg") {
    grid-row-gap: 48px;
    grid-column-gap: 30px;
    grid-template-areas:
          "title title socials"
          "team team team"
          "actions actions actions";
  }

  @include media("<tablet") {
    grid-template-areas:
          "title title title"
          "team team socials"
          "actions actions actions";
  }

  @include media("<phone-lg") {
    padding: 20px;
    grid-gap: 40px;
    grid-template-areas:
          "title title"
          "team socials"
          "text text"
          "actions actions";
  }
}

.titleWrapper {
  grid-area: title;
}

.title {
  max-width: 854px;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  text-transform: uppercase;
  color: var(--white);

  @include media("<desktop-xlg") {
    font-size: 40px;
    line-height: 56px;
  }

  @include media("<tablet-lg") {
    line-height: 48px;
  }

  @include media("<tablet") {
    max-width: 498px;
    font-size: 32px;
    line-height: 48px;
  }

  @include media("<phone-lg") {
    font-size: 24px;
    line-height: 32px;
    max-width: 306px;
  }
}

.socialsWrapper {
  grid-area: socials;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-self: flex-end;
  align-self: flex-start;

  @include media("<tablet-lg") {
    flex-direction: column;
    align-items: flex-start;
  }

  @include media("<tablet") {
    flex-direction: row;
    align-items: center;
    align-self: center;
  }
}

.teamWrapper {
  grid-area: team;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 16px;
  flex-shrink: 0;

  @include media("<tablet-lg") {
    margin-top: 16px;
    flex-direction: row-reverse;
    justify-self: flex-start;
  }

  @include media("<tablet") {
    margin-top: 0;
  }

  @include media("<phone-lg") {
    align-items: flex-start;
    flex-direction: column-reverse;
    .teamText {
      display: none;
    }
  }
}

.teamTextWrapper {
  grid-area: text;
  display: none;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  margin-top: -28px;

  @include media("<phone-lg") {
    display: block;
  }
}

.teamText {
  flex-shrink: 0;
  max-width: 246px;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: var(--grey-6);
  text-shadow: 0 0 40px rgba(61, 253, 149, 0.24);

  span {
    color: var(--white);
    font-weight: 700;
  }

  @include media("<tablet-lg") {
    text-align: left;
  }

  @include media("<phone-lg") {
    max-width: 278px;
  }
}

.avatarsWrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.avatarWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 72px;
  height: 72px;
  -webkit-mask-image: url(../../../assets/images/firstAvatarMask.svg);
  -webkit-mask-position: right center;
  -webkit-mask-repeat: no-repeat;
  mask-image: url(../../../assets/images/firstAvatarMask.svg);
  mask-position: right center;
  mask-repeat: no-repeat;

  &:not(:first-child) {
    margin-left: -16px;
    -webkit-mask-image: url(../../../assets/images/secondAvatarMask.svg);
    mask-image: url(../../../assets/images/secondAvatarMask.svg);

  }


  &:after {
    content: "";
    position: absolute;
    bottom: 5px;
    right: 4px;
    width: 12px;
    height: 12px;
    background-color: var(--green-2);
    border-radius: 50%;
  }
}

.avatar {
  width: 100%;
  height: 100%;
  mask-image: radial-gradient(circle 10px at calc(100% - 6px) calc(100% - 7px), transparent 9px, var(--black) 0);
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
    border-radius: inherit;
  }
}

.actionsWrapper {
  grid-area: actions;
  display: flex;
  gap: 16px;
  justify-self: flex-start;

  @include media("<tablet-lg") {
    justify-self: unset;
  }

  @include media("<phone-lg") {
    flex-direction: column;
  }

}

.contactUsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 64px;
  padding: 0 40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  white-space: nowrap;
  transition: 0.25s text-shadow ease-in-out;

  &:hover {
    text-shadow: 0 0 24px currentColor;
  }

  @include media("<tablet-lg") {
    flex-grow: 1;
  }
}

.emailTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: currentColor;
}

.callButton {
  white-space: nowrap;
  background-color: var(--green-2);
  color: var(--dark-1);
  height: 64px;
  padding: 0 40px;
  font-weight: 700;
  font-size: 18px;

  @include media("<tablet-lg") {
    flex-grow: 1;
  }
}

.separator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 112px;
  height: 1px;
  background-color: var(--green-7);
  display: none;

  @include media("<tablet-lg") {
    display: block;
  }

  @include media("<phone-lg") {
    bottom: 184px;
  }
}

.light {
  background-color: var(--white);

  .title {
    color: var(--dark-1);
  }

  .teamTextWrapper {

    background: rgba(0, 0, 0, 0.04);
  }

  .contactUsButton {
    color: var(--grey-9);
  }

  .separator {
    background-color: var(--light-5);
  }

  .teamText {
    span {
      color: var(--dark-1)
    }
  }
}
