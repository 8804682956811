@import "../../../../../../../../styles/media-mixin.scss";

.container {
  color: var(--dark-1);
  margin-bottom: 20px;
}

.horizontal {

  .contentWrapper {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    @include media("<tablet-lg") {
      flex-direction: column;
    }

  }
}

.stepTitleWithSeparatorWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.stepTitleWithSeparator {
  color: var(--grey-1);
  flex-shrink: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  text-transform: uppercase;
  margin-right: 16px;
}

.separator {
  background-color: var(--grey-2);
  height: 1px;
  flex-grow: 1;
}


.titleWrapper {
  flex-shrink: 0;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  //align-items: center;

  @include media("<phone-lg") {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  max-width: 334px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @include media("<tablet-lg") {
    max-width: 424px;
  }

  @include media("<phone-lg") {
    order: 2;
  }
}

.withContent {
  max-width: 294px;
  width: 294px;

  @include media("<tablet-lg") {
    width: max-content;
    max-width: 424px;
  }
}

.descriptionWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  //align-items: center;

  @include media("<tablet-lg") {
    align-items: flex-start;
  }
}

.descriptionItemWrapper {
  max-width: 540px;

  &.withBorder:not(:first-child) {
    border-top: 1px solid var(--grey-2);
    padding-top: 20px;
  }
}


.subTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.text {
  font-size: 16px;
  line-height: 24px;

  &:not(.withoutMargin) {
    margin-bottom: 24px;
  }
}

.stepTitleWrapper {
  color: var(--grey-1);
  flex-shrink: 0;
  width: 110px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  text-transform: uppercase;

  &.leftAlign {
    text-align: left;
    margin-bottom: 24px;
  }

  @include media("<phone-lg") {
    width: max-content;
    text-align: left;
    order: 1;
    margin-bottom: 16px;
  }
}
