.container {
  position: sticky;
  top: var(--header-height);
  width: 100%;
  padding: 12px;
  background-color: var(--dark-1-88);
  order: 1;
  margin-bottom: 12px;
  z-index: 1;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  cursor: pointer;
}

.menu {
  width: calc(100% - 24px);
  transform: translateY(-100px);
  overflow: hidden;
  transition: 0.3s opacity ease-in-out, 0.15s transform ease-in-out;
}

.menuShow {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(0px);
}

.menuSelectWrapper {
  background-color: var(--dark-1-88);
  padding: 0 20px 20px;
}

.menuTitle {
  color: var(--light-1);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transition: 0.15s color ease-in-out, 0.15s font-size ease-in-out, 0.15s font-weight ease-in-out;
}

.titleOpen {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--white);
}

.menuActiveItem {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--white);
  height: 24px;
  transition: 0.15s height ease-in-out;
  overflow: hidden;
}

.menuActiveItemHide {
  height: 0;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--black-12);
  color: var(--white);
  background-color: var(--black-4);
  transition: 0.25s transform ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.open {
  transform: scale(-1);
}