.buttonWrapper {
  position: relative;
  border-radius: 4px;
  height: 48px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: var(--white);
  margin: 0 2px;
  background: transparent;
  border: none;
  transition: 0.25s color ease-in-out;

  &:not(.withBackground) .button {
    border-color: var(--dark-2);
  }

  &.gradientBackground,
  &:not(.disabledDefaultHover):hover {
    .button {
      background: rgba(58, 68, 89, 0.24);
      box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.1), inset 2px 4px 12px rgba(83, 129, 163, 0.12);
    }
  }
}

.fullWidth {
  width: 100%;
}

.cursor {
  cursor: pointer;
}

.button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  z-index: 1;
  transition: 0.25s background-color ease-in-out, 0.25s box-shadow ease-in-out;
}


.icon {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;

  &:not(.withoutMargin) {
    margin-right: 8px;
  }
}

.content {
  position: relative;
  z-index: 2;
}

.left-side {
  padding: 12px 42px 12px 0;

  &.largePaddings {
    padding: 12px 62px 12px 20px;
  }

  &.smallPaddings {
    padding: 12px 24px 12px 0;
  }

  .button {
    left: -20px;
    right: 20px;
    transform: skewX(20deg);
  }
}

.right-side {
  padding: 12px 0 12px 42px;

  &.largePaddings {
    padding: 12px 20px 12px 62px;
  }

  &.smallPaddings {
    padding: 12px 0 12px 36px;
    margin: 0;

    .button {
      left: 24px;
      right: -16px;
    }
  }

  .button {
    left: 20px;
    right: -20px;
    transform: skewX(-20deg);
  }
}

.left-side-first {
  border-left: 1px solid transparent;
  padding: 12px 42px 12px 12px;
  margin-left: 0;

  &.largePaddings {
    padding: 12px 62px 12px 32px;
  }

  &.smallPaddings {
    padding: 12px 36px 12px 8px;

    .button {
      left: -26px;
      right: 14px;
    }
  }

  &:not(.withBackground) {
    border-left-color: var(--dark-2);
  }

  &.gradientBackground,
  &:hover {
    box-shadow: inset -2px 0 -8px rgba(84, 97, 125, 0.1), inset -2px 0 -12px rgba(83, 129, 163, 0.12);
  }

  .button {
    left: -20px;
    right: 20px;
    transform: skewX(20deg);
  }
}

.left-side-last {
  border-right: 1px solid transparent;
  padding: 12px 12px 12px 42px;
  margin-right: 0;

  &:not(.withBackground) {
    border-right-color: var(--dark-2);
  }

  &.gradientBackground,
  &:hover {
    box-shadow: inset 2px 0 -8px rgba(84, 97, 125, 0.1), inset 2px 0 -12px rgba(83, 129, 163, 0.12);
  }

  .button {
    left: 20px;
    right: -20px;
    transform: skewX(20deg);
  }
}

.right-side-last {
  border-right: 1px solid transparent;
  padding: 12px 12px 12px 42px;
  margin-right: 0;

  &.largePaddings {
    padding: 12px 32px 12px 62px;
  }

  &.smallPaddings {
    padding: 12px 10px 12px 38px;
    margin: 0;

    .button {
      left: 24px;
      right: -16px;
    }
  }

  &:not(.withBackground) {
    border-right-color: var(--dark-2);
  }

  &.gradientBackground,
  &:hover {
    box-shadow: inset 2px 0 -8px rgba(84, 97, 125, 0.1), inset 2px 0 -12px rgba(83, 129, 163, 0.12);
  }

  .button {
    left: 20px;
    right: -20px;
    transform: skewX(-20deg);
  }
}

.right-side-first {
  border-left: 1px solid transparent;
  padding: 12px 42px 12px 12px;
  margin-left: 0;

  &:not(.withBackground) {
    border-left-color: var(--dark-2);
  }

  &.gradientBackground,
  &:hover {
    box-shadow: inset -2px 0 -8px rgba(84, 97, 125, 0.1), inset -2px 0 -12px rgba(83, 129, 163, 0.12);
  }

  .button {
    left: -20px;
    right: 20px;
    transform: skewX(-20deg);
  }
}

.left-side-first,
.left-side-last,
.right-side-first,
.right-side-last {
  overflow: hidden;
}

.default {
  padding: 0 24px;

  .content {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.iconWithFullWidth {
  margin: 0 10px 0 auto;
}

.contentWithFullWidth {
  margin: 0 auto;
}

.withIcon {
  margin: 0 auto 0 0;
}


