@import "../../../../../../styles/media-mixin.scss";

.boxWrapper {
  flex-shrink: 0;
  position: relative;
  width: 456px;
  height: 258px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background: radial-gradient(44.4% 32.83% at 48.13% -0.39%, rgba(156, 255, 219, 0.066) 0%, rgba(156, 255, 219, 0) 100%), radial-gradient(42.04% 88.01% at 50.49% 87.62%, rgba(61, 253, 184, 0.05) 0%, rgba(61, 253, 184, 0) 100%), linear-gradient(180deg, rgba(25, 31, 45, 0.3) -0.39%, #191F2D 26.12%, rgba(25, 31, 45, 0.04) 95.27%), var(--dark-1);
  border-radius: 8px;
  border: 1px solid var(--green-4);
  padding: 24px;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 8px;
    background: var(--green-4);
    z-index: -1;
  }
}

.box-center-horizontal {
  padding: 24px 40px;

  @include media("<desktop-lg") {
    flex-shrink: 1;
  }
}

.box-center-vertical {
  padding: 40px 24px;
}

.box-left,
.box-right,
.box-top,
.box-bottom {
  & > * {
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: 1;
    pointer-events: none;
  }
}

.box-left,
.box-right {

  @include media("<desktop-lg") {
    flex-shrink: 1;
  }
}

.box-left {
  padding-right: 40px;
  width: 266px;
  background: linear-gradient(270deg, #191F2D 0%, #151922 91.67%);

  &:after {
    background: linear-gradient(90deg, var(--dark-1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.box-right {
  padding-left: 40px;
  width: 266px;
  background: linear-gradient(270deg, #151922 0%, #191F2D 91.67%);

  &:after {
    background: linear-gradient(270deg, var(--dark-1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.box-top {
  background: linear-gradient(0deg, #191F2D 0%, #151922 91.67%);

  &:after {
    background: linear-gradient(var(--dark-1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.box-bottom {
  background: linear-gradient(180deg, #191F2D 0%, #151922 91.67%);

  &:after {
    background: linear-gradient(0deg, var(--dark-1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.island {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 96px;
  height: 16px;
  background-color: var(--dark-1);
  border-bottom: 1px solid var(--green-4);

  &.bottom {
    top: unset;
    bottom: -1px;
    transform: translateX(-50%) rotate(180deg);
  }

  &.left,
  &.right {
    top: 50%;
  }

  &.left {
    left: 7px;
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  &.right {
    left: unset;
    right: -89px;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    top: 0;
    width: 32px;
    background-color: var(--dark-1);
    border-bottom: 1px solid var(--green-4);
  }

  &:before {
    left: -16px;
    transform: skew(60deg);
    border-left: 2px solid var(--green-4);
    border-bottom-left-radius: 4px;
  }

  &:after {
    right: -16px;
    transform: skew(-60deg);
    border-right: 2px solid var(--green-4);
    border-bottom-right-radius: 4px;
  }
}

.islandLine {
  position: absolute;
  width: 68px;
  height: 5px;
  bottom: 0;
  left: 50%;
  background-color: var(--green-5);
  border-radius: 2px;
  transform: translate(-50%, 50%);
  z-index: 5;
}