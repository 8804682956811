@import "../../../../styles/media-mixin.scss";

.mainSectionWrapper {
  border-radius: 4px;
  background-image: radial-gradient(89.32% 134.19% at 93.49% 10.68%, rgba(61, 253, 149, 0.05) 0%, rgba(61, 253, 149, 0) 100%);
  background-color: var(--dark-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--dark-2);
  overflow: hidden;
}

.contentWrapper {
  margin-top: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mainSectionTitle {
  max-width: 628px;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  color: var(--white);
  text-align: center;
  text-transform: uppercase;

  @include media("<tablet") {
    font-size: 56px;
    line-height: 72px;
  }

  @include media("<phone-lg") {
    font-size: 40px;
    line-height: 48px;
  }
}

.mainSectionDescription {
  text-transform: uppercase;
  //margin-top: 64px;
  max-width: 454px;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  color: var(--white);
  text-align: center;

  @include media("<phone-lg") {
    font-size: 22px;
    line-height: 32px;
  }
}

.cropsWrapper {
  position: relative;
  background-size: cover;
  height: 162px;
  width: calc(100% + 2px);
  bottom: -1px;
  left: -1px;
  right: -1px;
}
