@import "../../../../../styles/media-mixin.scss";

.cases {
  margin-top: 40px;
}

.gridLayout {
  margin-top: 32px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr);
  //grid-template-rows: 568px;
  grid-auto-rows: 568px;

  & :nth-child(1), :nth-child(4) {
    grid-column: span 4;
  }

  & :nth-child(2), :nth-child(3) {
    grid-column: span 2;
  }

  & :nth-child(5), :nth-child(6) {
    grid-column: span 3;
  }

  @include media("<desktop-lg") {
    & :nth-child(n) {
      grid-column: span 3;
    }
  }

  @include media("<tablet") {
    grid-auto-rows: 350px;
    & :nth-child(n) {
      grid-column: span 6;
    }
  }
}
