.container {
  border-radius: 4px;
  background-size: cover;
  height: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.title {
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  color: var(--dark-1);
  text-align: center;
  text-transform: uppercase;
}

.description {
  margin-top: 32px;
  max-width: 332px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--grey-1);
}
