@import "../../../../../../../../styles/media-mixin.scss";

.container {
  margin: 20px 0;
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.list {
  margin-top: 24px;
  columns: 2;
  counter-reset: item;

  @include media("<tablet-lg") {
    columns: 1;
  }
}

.listItem {
  font-size: 14px;
  line-height: 20px;
  display: flex;

  @include media("<tablet-lg") {
    max-width: 520px;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.numbered {
  list-style-type: none;
}

.numbered::before {
  margin-right: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--white);
  content: counter(item);
  counter-increment: item;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
