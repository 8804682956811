@import "../../../../styles/media-mixin.scss";

.sectionWrapper {
  overflow-x: hidden;
}

.container {
  position: relative;
  display: flex;
}

.backgroundWrapper {
  position: absolute;
  top: 0;
  bottom: 112px;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid var(--dark-2);
  border-radius: 4px;
}

.mainBlock {
  position: relative;
  flex: 1;
  display: grid;
  grid-template-areas:
    "content members-list"
    "contacts members-list";
  grid-auto-columns: auto 234px;

  @include media("<desktop-lg") {
    grid-template-areas:
      "content"
      "members-list"
      "contacts ";
    grid-auto-columns: auto;
  }

  @include media("<tablet") {
    grid-template-areas:
      "members-list"
      "content"
      "contacts ";
    //grid-auto-columns: auto;
  }

}

.contentWrapper {
  grid-area: content;
  //min-height: 600px;
  padding: 24px 0;
}

.teamMemberRole,
.teamMemberName {
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 24px;
}

.teamMemberRole {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-4);

  @include media("<tablet") {
    font-size: 14px;
    line-height: 24px;
  }
}

.teamMemberName {
  font-size: 80px;
  line-height: 80px;
  color: var(--white);

  @include media("<tablet") {
    font-size: 48px;
    line-height: 56px;
  }
}

.featuresWrapper {
  padding: 0 24px 24px;
  margin-top: 90px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--dark-2);
  max-width: calc(100% - 100px);

  @include media("<tablet") {
    max-width: 100%;

  }

}

.features {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 290px;
  max-width: 350px;
  overflow-y: auto;

  @include media("<tablet") {
    margin-top: 40px;
  }
}

.teamMembersList {
  grid-area: members-list;
}

.contacts {
  grid-area: contacts;
  padding: 32px 0;
  display: flex;
}

.memberImageContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 796px;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
}

.callButton {
  .callButtonInner {
    background-color: var(--green-2);
  }

  &:hover {
    .callButtonInner {
      background-color: var(--green-2-hover);
    }
  }
}


.memberImage {
  position: absolute;
  right: 250px;
  bottom: 0;
  height: 796px;
  //animation: 20s imageAnimation ease-in-out infinite;

  @include media("<desktop-lg") {
    right: -128px;
  }

  @include media("<tablet-lg") {
    right: -192px;
  }

  @include media("<tablet") {
    display: none;
  }
}

@keyframes imageAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
