@import "../../../../styles/media-mixin";

.section {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  border-radius: 8px;
  border: 1px solid var(--dark-2);
  overflow: hidden;
}

.titleWrapper {
  display: block;
  padding-top: 60px;
}

.title {
  background: linear-gradient(92.62deg, #85C8FF 28.23%, #FF7F57 70.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  padding: 0 12px;
  text-shadow: 0 2px 0 #07091120;
  text-align: center;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 700;

  @include media("<tablet") {
    font-size: 48px;
  }

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 40px;
  }
}

.description {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  max-width: 504px;
  padding: 16px 12px 0;
  margin: 0 auto;
  color: var(--grey-4);

  @include media("<tablet") {
    font-size: 18px;
    line-height: 32px;
  }

  @include media("<phone-lg") {
    font-size: 16px;
    line-height: 24px;
  }
}

.grid {
  position: relative;
  background-repeat: no-repeat;
  background-size: 1414px 354px;
  background-position: center bottom;
  height: 354px;
  margin-top: 62px;

  @include media("<tablet-lg") {
    height: 278px;
    background-size: 1108px 278px;
    margin-top: 140px;
  }

  @include media("<tablet") {
    height: 230px;
    background-size: 920px 230px;
  }

  @include media("<phone-lg") {
    margin-top: 68px;
  }
}

