.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
}

.subContainer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.icon {
  color: var(--grey-2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  max-width: 660px;
  font-weight: 900;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-style: italic;
}

.closeIcon {
  transform: rotate(180deg);
}