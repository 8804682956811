@import "../../../../../styles/media-mixin.scss";

.projectsBlockContentFlex {

  @include media("<desktop-xlg") {
    &.view-desktop-xlg {
      .inner,
      & + .projectsBlockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<desktop-lg") {
    &.view-desktop-lg {
      .inner,
      & + .projectsBlockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<desktop") {
    &.view-desktop {
      .inner,
      & + .projectsBlockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<tablet-lg") {
    overflow-x: auto;
    .inner {
      flex-wrap: nowrap;
      min-width: max-content;
    }

    &.view-tablet-lg {
      .inner {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<tablet") {
    &.view-tablet {
      .inner {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<phone-lg") {
    &.view-desktop-xlg,
    &.view-desktop-lg,
    &.view-desktop,
    &.view-tablet-lg,
    &.view-tablet,
    &.view-phone-lg {
      .inner {
        padding-left: var(--padding-mobile);
        padding-right: var(--padding-mobile);
      }
    }
  }
}

.inner {
  display: flex;
  gap: 4px;
}

.projectsBlockActionsWrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.projectsBlockPaginationButton {
  --button-color: var(--grey-4);
  --button-hover-color: var(--green-2);
  --button-border-color: var(--dark-3);
  --button-active-color: var(--green-2)
}

.projectsBlockShowMoreButton {
  color: var(--grey-4);
  border: 1px solid var(--dark-3);
  border-radius: 4px;
  text-transform: uppercase;

  &:hover {
    color: var(--green-2);
  }
}
