@import "../../../styles/media-mixin";

.storiesBlockWrapper {
  padding-bottom: 64px;
  border-bottom: 1px solid var(--light-1-12);
}

.storiesBlockContentGrid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 4px;

  @include media("<desktop-lg") {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media("<tablet-lg") {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-columns: unset;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

  }
}

.storiesBlockActionsWrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.storiesBlockPaginationButton {
  --button-color: var(--grey-4);
  --button-hover-color: var(--green-2);
  --button-border-color: var(--dark-3);
  --button-hover-border-color: var(--green-2);
  --button-active-color: var(--green-2)
}

.storiesBlockShowMoreButton {
  color: var(--grey-4);
  border: 1px solid var(--dark-3);
  border-radius: 4px;
  text-transform: uppercase;

  &:hover {
    color: var(--green-2);
  }
}

.overlay {
  //backdrop-filter: blur(1px);
}