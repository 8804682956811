@import "../../../../styles/media-mixin.scss";

.cardsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 4px;

  @include media("<tablet") {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.cardItemWrapper {
  flex: 1;
  background: linear-gradient(180deg, var(--dark-7) 0%, rgba(35, 39, 49, 0) 100%);
  border-radius: 4px;
  padding: 20px;
  height: 208px;

  @include media("<tablet") {
    height: unset;
  }
}

.cardTitle {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--white);
}

.cardDescription {
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-7);

  @include media("<tablet") {
    max-width: 504px;
  }
}

.meetBlockWrapper {
  position: relative;
  margin-top: 40px;
  display: flex;
  padding: 20px 20px 20px 252px;
  align-items: center;
  border: 1px solid var(--green-2-10);
  border-radius: 4px;
  gap: 16px;
  background: linear-gradient(180deg, rgba(61, 253, 149, 0) 0%, rgba(61, 253, 149, 0.07) 100%);

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(21, 25, 34, 0) 34.05%, rgba(21, 25, 34, 0.9) 99.31%);
    height: 72px;
    border-radius: 3px;
  }

  @include media("<tablet-lg") {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 318px;
  }

  @include media("<tablet") {
    padding-left: 336px;
  }

  @include media("<phone-lg") {
    padding: 180px 20px 20px;
    margin-top: 64px;
  }
}

.imageWrapper {
  position: absolute;
  left: 16px;
  bottom: 0;
  overflow: hidden;

  @include media("<tablet-lg") {
    left: 4px;
  }

  @include media("<phone-lg") {
    border-bottom: 1px solid var(--green-2-10);
    left: 0;
    bottom: 220px;
    width: 100%;
  }
}


.image {
  display: block;
  position: relative;
  width: 220px;

  @include media("<tablet-lg") {
    width: 304px;
  }

  @include media("<tablet") {
    width: 380px;
    left: -56px;
  }

  @include media("<phone-lg") {
    max-width: 336px;
    left: 50%;
    bottom: 0;
    display: block;
    transform: translateX(-50%);
  }
}

.textWrapper {
  flex: 1;
}

.meetBlockTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--white);

  span {
    color: var(--green-2);
  }
}

.meetBlockDescription {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-7);

  @include media("<tablet") {
    margin-top: 8px;
    max-width: 262px;
  }
}

.link {
  height: 52px;
  background-color: var(--green-2);
  color: var(--dark-1);
  outline: none;

  @include media("<phone-lg") {
    width: 100%;
  }
}

.statisticsWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 4px;

  @include media("<phone-lg") {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.statisticItemWrapper {
  flex: 1;
  background: linear-gradient(180deg, var(--dark-7) 0%, rgba(35, 39, 49, 0) 100%);
  border-radius: 4px;
  height: 288px;
  display: flex;
  flex-direction: column;

  &:last-child {
    @include media("<tablet") {
      display: none;
    }
  }
}

.statisticItemMainWrapper {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.statisticItemMainTitle {
  max-width: 190px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--white);
}

.statisticItemMainValue {
  font-size: 80px;
  line-height: 64px;
  text-transform: uppercase;
  color: var(--light-1);
}

.statisticItemBottomWrapper {
  height: 56px;
  background-color: var(--dark-7);
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.statisticItemBottomValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--green-2);
}

.statisticItemBottomTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--grey-4);
}
