@import "../../../../../../../styles/media-mixin.scss";

.reviewContainer {
  background: var(--dark-1);
  border: 1px solid var(--grey-10);
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.headerSection {
  position: relative;
  display: flex;
  height: 170px;
  padding: 24px 24px 16px;
  border-bottom: 1px solid var(--grey-10);

  @include media("<tablet") {
    height: 140px;
  }
}

.authorAvatarWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 270px;
  overflow: hidden;

  @include media("<phone-lg") {
    left: 50%;
    transform: translateX(-50%);
  }
}

.authorAvatar {
  display: block;
  width: 100%;
  height: auto;
}

.logoWrapper {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 136px;

  @include media("<tablet") {
    width: 36px;
    right: 8px;
  }
}

.logo {
  width: 100%;
  height: auto;
}


.infoWrapper {
  flex: 1;
  margin-left: 246px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;

  @include media("<tablet-lg") {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }

  @include media("<phone-lg") {
    margin-left: 0;
  }
}

.authorInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.authorName {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--white);
}

.authorRole {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--grey-7);
}

.reviewInfo {
  display: flex;
  gap: 16px;

  @include media("<tablet") {
    flex-direction: column;
    gap: 8px;
  }
}

.cooperationDates,
.companyLocation {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--grey-7);
}


.contentSection {
  padding: 40px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;

  @include media("<tablet") {
    grid-template-columns: 1fr;
    padding: 24px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.iconWrapper {
  color: var(--green-2);
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: var(--white);

  @include media("<phone-lg") {
    font-size: 24px;
    line-height: 32px;
  }
}

.ratingWrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.clutchIconWrapper {
}

.descriptionBlockBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.textItem {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--grey-7);
}
