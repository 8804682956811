.featureWrapper {
  display: flex;
}

.iconWrapper {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--green-2);
  border-radius: 50%;
  border: 1px solid var(--dark-2);
}

.featureContentWrapper {
  margin-left: 12px;
  //max-width: 310px;
}

.featureTitle {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--white);
}

.featureDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-4);
}
