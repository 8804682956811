@import "../../../../../../styles/media-mixin.scss";

.skillsWrapper {
  position: relative;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  z-index: 1;
}

.skillItem {
  flex-shrink: 0;
  padding: 4px 12px;
  background-color: var(--dark-6);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  border-radius: 4px;
}

.skillItemButton {
  cursor: pointer;
  border: none;
  color: var(--grey-4);

  @include media("<phone-lg") {
    width: 100%;
  }
}

.skillsPopupWrapper {
  max-width: 400px;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--dark-6);
}

.skillsPopupTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--grey-4);
}

.skillsPopupContent {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.skillPopupItem {
  padding: 4px 12px;
  border: 2px solid var(--dark-2);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
}

