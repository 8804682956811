@import "../../styles/media-mixin";

.container {
  position: fixed;
  top: 0;
  left: auto;
  max-width: 100vw;
  width: 1440px;
  padding: var(--padding-desktop);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  background: var(--dark-1);

  @include media("<phone-lg") {
    padding: 12px var(--padding-mobile);
  }
}

.logoWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.containerAsideSection {
  display: flex;
  align-items: center;
}

.contactUsButton {

  .contactUsButtonInner {
    background-color: var(--green-2);
  }

  &:hover {
    .contactUsButtonInner {
      background-color: var(--green-2-hover);
    }
  }
}
