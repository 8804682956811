.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  color: var(--grey-4);
  background-color: var(--white-08);
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.25s color ease-in-out, 0.25s background-color ease-in-out, 0.25s border-color ease-in-out;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  //&:not(:first-child) {
  //  margin-top: 4px;
  //}

  &.active {
    color: var(--green-2);
    background-color: transparent;
    border-color: var(--green-2);
  }

  &:hover {
    color: var(--green-2);
    border-color: var(--green-2);
  }
}