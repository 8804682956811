@import "../../../styles/media-mixin";

.section {
  padding-left: var(--padding-desktop);
  padding-right: var(--padding-desktop);

  @include media("<desktop-xlg") {
    &.hideDesktopXLGPadding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media("<tablet") {
    &.hideTabletPadding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media("<phone-lg") {
    &.hideMobilePadding {
      padding-left: 0;
      padding-right: 0;
    }
    &:not(.hideMobilePadding) {
      padding-left: var(--padding-mobile);
      padding-right: var(--padding-mobile);
    }
  }
}
