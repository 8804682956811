.layout {
  display: flex;
}

.layoutContent {
  flex: 1;
}

.layoutSidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 448px;
  padding-left: 20px;
  padding-right: var(--padding-desktop);
}
