@import "../../../styles/media-mixin";

.grid {
  &.mobileScroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.gridInner {
  display: grid;
  grid-template-columns: repeat(var(--columns-lg), 1fr);
  gap: var(--grid-gap-desctop);

  @include media("<desktop-xlg") {
    grid-template-columns: repeat(var(--columns-md), 1fr);
  }

  &:not(.mobileScroll) {
    @include media("<tablet-lg") {
      grid-template-columns: repeat(var(--columns-sm), 1fr);
    }

    @include media("<phone-lg") {
      grid-template-columns: repeat(var(--columns-xs), 1fr);
      gap: var(--grid-gap-mobile);
    }
  }

  &.mobileScroll {
    @include media("<phone-lg") {
      grid-auto-flow: column;
      grid-auto-columns: 312px;
      grid-template-columns: unset;
      min-width: max-content;
      padding-left: var(--padding-mobile);
      padding-right: var(--padding-mobile);
      gap: var(--grid-gap-mobile);
    }
  }

}
