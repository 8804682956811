.teamHeaderSectionContainer {
  background-size: cover;
  background-position: bottom;
  overflow-y: hidden;
}

.contentLayout {
  position: relative;
}

.sidebarWrapper {
  margin-top: 172px;
  position: sticky;
  top: var(--header-height);
}