@import "../../../../../../styles/media-mixin.scss";

.membersList {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 1px 0 0 0;

  @include media("<desktop-lg") {
    padding: 0 0 0 1px;
  }

  @include media("<tablet") {
    padding: 0 1px 0 1px;
  }
}

.membersItemsWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  overflow: hidden auto;
  flex: 1 0;

  @include media("<desktop-lg") {
    overflow: auto hidden;
    flex-direction: row;
  }
}

.listBottomZone {
  min-height: 24px;
  width: 100%;
}

.showMoreButtonWrapper {
  position: relative;
  top: 24px;
  color: var(--grey-4)
}

.showMoreButton {
  width: 100%;
  border: 1px solid var(--dark-2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background: transparent;
  color: var(--grey-4);
  transition: 0.25s border-color ease-in-out, 0.25s color ease-in-out;

  &:not(.disabled) {
    cursor: pointer;
  }

  &:not(.disabled):hover {
    border-color: var(--grey-4);
    color: var(--grey-3);
  }
}

.disabled {
  color: var(--dark-3);
  border-color: var(--dark-3);

}

.showMoreTitle {
  margin-top: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
}
