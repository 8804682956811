@import "../../../styles/media-mixin";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  border-radius: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  transition: 0.25s background-color ease-in-out, 0.25s color ease-in-out, 0.25s border-color ease-in-out;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid transparent;
  background-color: transparent;
  flex-shrink: 0;

  &:not(.disabled):not(.loading) {
    cursor: pointer;
  }
}

.uppercase {
  text-transform: uppercase;
}

.iconWrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.loader {
  img {
    width: 20px;
    height: 20px;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.iconBefore {
  margin-right: 12px;
}

.iconAfter {
  margin-left: 12px;
}

.success {
  position: relative;
  color: var(--dark-1);
  background-color: var(--green-2);

  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    border-radius: inherit;
    mix-blend-mode: overlay;
    background-blend-mode: multiply, normal;
    transition: background-color .25s ease-in-out;
  }

  &:not(&.loading):not(&.disabled) {
    &:hover {
      &:after {
        background-color: rgba(0, 0, 0, .3);
      }
    }
  }

  &.loading,
  &.disabled {
    color: inherit;
    background-color: var(--grey-2);
  }
}

.outline {
  color: var(--grey-1);
  border-color: var(--grey-2);
}

.small {
  height: 32px;
}

.medium {
  height: 40px;
}

.large {
  height: 48px;
}

.fullWidth {
  width: 100%;
}

.XLarge {
  height: 80px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--dark-1);

  @include media("<tablet-lg") {
    height: 56px;
    font-size: 16px;
    margin-top: 20px;
  }
}

.rounding-small {
  border-radius: 4px;
}

.rounding-large {
  border-radius: 40px;
}

