.iframe {
  border-radius: 4px;
  width: calc(576px * 720 / 1280);
  height: 576px;
}

.transparentCover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}