@import "../../styles/media-mixin";

.layout {
  padding-top: 48px;

  @include media("<desktop-xlg") {
    padding-top: 40px;
  }

}


.divider {
  position: relative;
  padding-top: 40px;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(270deg, rgba(30, 36, 47, 0) 0%, #1E242F 25.12%, #1E242F 74.7%, rgba(30, 36, 47, 0) 100%);
  }
}
