@import "../../../../../styles/media-mixin.scss";

.container {
  padding-left: var(--padding-desktop);
  padding-right: var(--padding-desktop);

  @include media("<phone-lg") {
    //padding-left: var(--padding-mobile);
    padding-left: 0;
    //padding-right: var(--padding-mobile);
    padding-right: 0;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.tagName {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-transform: uppercase;
  color: var(--green-2);
  //margin-bottom: 20px;

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 48px;
  }
}

.showMoreButton {

}

.contentWrapper {
  display: flex;
  gap: 4px;

  @include media("<tablet-lg") {
    & > :last-child {
      display: none;
    }
  }


  @include media("<phone-lg") {
    flex-direction: column;
  }


}
