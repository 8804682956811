.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;

  &.behance,
  &.instagram,
  &.telegram,
  &.whatsApp {
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border-radius: inherit;
      mix-blend-mode: overlay;
      transition: background-color .25s ease-in-out;
    }

    &:hover:after {
      background-color: rgba(0, 0, 0, .3);
    }
  }

  &.instagram {
    background: radial-gradient(221.7% 98.44% at 28.12% 1.56%, #3748E1 8.05%, #9F35B3 37.8%, rgba(209, 47, 144, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    , radial-gradient(79.26% 89.84% at 28.13% 95.31%, #FFEB92 0%, #F6640E 47.77%, #FF1200 66.4%, rgba(209, 47, 144, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    , #CB2F9B;
  }

  &.behance {
    background: #1769FF;
  }
}
