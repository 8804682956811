@import "../../../../styles/media-mixin.scss";

.container {
  border-radius: 4px;
  padding: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sticky {
  position: sticky;
  top: var(--header-height);
}

.light {
  background: var(--light-1);
  color: var(--dark-1);
  cursor: pointer;

  &:hover {
    .arrowIconWrapper {
      transform: translate(10px, 10px);
    }
  }

  .desc {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;

    &.medium {

    }
  }

  .title {
    font-size: 40px;
    line-height: 48px;
    max-width: 404px;

    &.medium {
      font-size: 24px;
      line-height: 32px;
      max-width: 330px;
      margin-top: 48px;
      margin-bottom: 20px;
    }
  }


  .avatarsWrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    & :not(:first-child) {
      margin-right: -8px;
    }
  }

  .showMore {
    width: 56px;
    height: 56px;
    background: var(--dark-1);
    color: var(--light-1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid var(--light-1);
  }

  .sectionFooter {
    display: flex;
    align-items: center;
  }

  .sectionFooterTitle {
    margin-left: 20px;
    font-size: 16px;
    line-height: 18px;
    max-width: 88px;
    text-transform: uppercase;

    @include media("<phone-lg") {
      margin-left: auto;
    }
  }

  .arrowIconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    transition: 0.25s transform ease-in-out;

    @include media("<phone-lg") {
      display: none;
    }
  }
}

.dark {
  padding: 8px;
  gap: 8px;
  background: linear-gradient(136.04deg, #20242D 6.85%, var(--dark-7) 75.46%);

  .contentWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 24px;
    padding: 20px;
    background-color: #11141C;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    overflow: hidden;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      transition: 0.1s opacity ease-out, 0.05s transform ease-out;
    }

    &:before {
      background-image: url("../../../../assets/images/glassBroken.webp");
      z-index: 1;
      transform: scale(1.2);
    }

    &:after {
      background-image: url("../../../../assets/images/glass.webp");
      opacity: 0.5;
    }
  }

  .broken {
    animation: breakGlass 0.1s ease-out;

    &:before {
      opacity: 0.5;
      transform: scale(1);
    }

    &:after {
      opacity: 0;
    }
  }

  .imageWrapper {
    width: 70px;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }

  .textWrapper {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .caption,
  .title,
  .subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--white);
  }

  .caption {
    opacity: 0.5;
  }

  .title {
    color: var(--green-2);
    opacity: 0.9;
  }

  .subtitle {
    margin-top: 12px;
    opacity: 0.5;
  }

  .button {
    position: relative;
  }

  .buttonIcon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }


}

.uppercase {
  text-transform: uppercase;
}

@keyframes breakGlass {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

