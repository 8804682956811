@import "../../../../../styles/media-mixin.scss";

.reviewCardWrapper {
  width: 100%;
  border: 1px solid var(--grey-10);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  @include media("<tablet") {
    min-width: 520px;
    max-width: 520px;
  }

  @include media("<phone-lg") {
    min-width: 320px;
    max-width: 320px;
  }
}

.mobileLogoWrapper {
  height: 124px;
  border-bottom: 1px solid var(--grey-10);
  position: relative;
}

.mobileLogoInnerWrapper {
  position: absolute;
  left: 16px;
  bottom: 0;
  overflow: hidden;

  @include media("<phone-lg") {
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    display: block;
    max-height: 200px;
    width: auto;
  }
}

.companyName {
  line-height: 40px;
  font-weight: 700;
  font-size: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 12.29%, var(--white) 41.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.contentWrapper {
  flex: 1;
  position: relative;
  padding: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @include media("<tablet") {
    padding: 20px;
  }
}

.reviewTitle {
  height: 108px;
  max-width: 456px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--white);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reviewDescription {
  max-width: 362px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  line-height: 28px;
  color: var(--grey-7);
}

.readMoreButton {
  align-self: flex-start;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--green-2);
  padding: 0;
  transition: 0.25s text-shadow ease-in-out;

  &:hover {
    text-shadow: 0 0 24px currentColor;
  }
}

.authorInfoWrapper {
  padding: 20px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-top: 1px solid var(--grey-10);

  @include media("<tablet") {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  @include media("<phone-lg") {
    gap: 20px;
  }
}

.authorInfoBlock {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;

  @include media("<phone-lg") {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.authorName {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--white);
}

.authorRole {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-7);
  border: 1px solid var(--grey-10);
  border-radius: 4px;
}

.cooperationDates,
.companyLocation {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--grey-7);
}

.imageWrapper {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0;
  height: 426px;
  opacity: 0;
  transition: 0.25s opacity ease-in-out;
  will-change: opacity;

  .imageInnerWrapper {
    height: inherit;
    transition: 0.45s transform ease-in-out;
    transform: translate(64px, 32px) scale(0.7);
  }


  img {
    position: relative;
    right: -56px;
    bottom: 0;
    display: block;
    width: auto;
    height: 100%;
  }

  @include media("<tablet") {
    display: none;
  }
}

.showImage {
  opacity: 1;

  .imageInnerWrapper {
    transform: translate(0, 0) scale(1);
  }
}

.modal {
  margin: 0 !important;
}
