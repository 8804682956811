@import "../../../../styles/media-mixin.scss";

.container {
  position: relative;
  display: flex;
  margin-top: 64px;
  padding: 32px 0;
  overflow: hidden;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 56px;
    z-index: 10;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, var(--dark-1) 0%, rgba(21, 25, 34, 0) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(270deg, var(--dark-1) 0%, rgba(21, 25, 34, 0) 100%);
  }
}

.logosWrapper {
  display: flex;
  flex-shrink: 0;
  color: var(--grey-11);
  align-items: center;
  animation: loopLogos 50s linear infinite;

  svg {
    margin: 0 60px;
  }

  @include media("<tablet-lg") {
    svg {
      margin: 0 40px;
    }
  }
}

@keyframes loopLogos {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
