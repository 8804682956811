.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  flex-grow: 1;
}

.withPaddings {
  padding: 20px;
}

.showMore {
  margin-right: auto;
  border-radius: 4px;
}

.paginationButton {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  color: var(--button-color);
  border-color: var(--button-border-color);

  svg {
    width: 10px;
    color: currentColor;
  }

  &:not(.disabled):hover {
    color: var(--button-hover-color);
    border-color: var(--button-hover-color);
  }
}

.active {
  color: var(--button-active-color) !important;
}

.prevNextButtonsContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
}

.iconWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevNextButton {
  width: 80px;
  color: var(--white);
}