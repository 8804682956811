.popup {
  display: flex;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: 0.15s opacity ease-in-out;
  color: black;
  border-radius: 12px;
  overflow: hidden;
  z-index: 103;
}

.test {
  width: 300px;
  height: 450px;
  border-radius: 8px;
  background-color: var(--color-grey-05-solid);
  display: flex;
  align-items: center;
  justify-content: center;
}

.layer {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  transition: 0.25s opacity ease-in-out;
  z-index: var(--popup-z-index);
}

.show {
  pointer-events: auto;
  opacity: 1;
}

.hide {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
