@import "../../../../styles/media-mixin";

.caption {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-4);
  padding-bottom: 16px;

  padding-left: var(--padding-desktop);
  padding-right: var(--padding-desktop);

  @include media("<phone-lg") {
    padding-left: var(--padding-mobile);
    padding-right: var(--padding-mobile);
  }
}

.tabs {
  &.horizontal {
    overflow-x: scroll;
    overflow-y: hidden;

    & .tabsInner {
      display: flex;
      min-width: max-content;
      gap: 8px;

      @include media("<desktop-xlg") {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-mobile);
        gap: 16px;
      }

      @include media("<phone-lg") {
        padding-left: var(--padding-mobile);
        padding-right: var(--padding-mobile);

      }
    }

    & .tabDescription {
      max-height: unset;
      margin-top: 8px;
    }

    & .tab {
      margin-bottom: 0;
      width: 336px;

      @include media("<phone-lg") {
        width: 312px;
      }
    }
  }
}

.tab {
  background-color: var(--dark-1);
  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--dark-2);
  cursor: pointer;
  transition: .25s border-color ease-in-out, .25s background-color ease-in-out;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &.active {
    border-color: rgba(61, 253, 184, 0.5);
    background-color: rgba(61, 253, 184, 0.04);;
    .tabDescription {
      margin-top: 8px;
    }
  }
}

.tabTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--green-2);
  text-shadow: 0 0 40px rgba(61, 253, 149, 0.25);
}

.tabDescription {
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-green);
  margin-top: 0;
  max-height: 0;
  overflow: hidden;
  transition: .25s margin-top ease-in-out, .25s max-height ease-in-out;
}
