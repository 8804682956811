@import "../../../../styles/media-mixin.scss";

.stagesWrapper {
}

.tabsWrapper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 1fr);

  @include media("<tablet") {
    grid-auto-flow: column;
    grid-template-columns: unset;
    grid-auto-columns: minmax(132px, 1fr);
    overflow-x: auto;
  }
}

.tabItem {
  position: relative;
  color: var(--grey-4);
  border: 1px solid transparent;
  border-bottom-color: var(--dark-2);
  border-radius: 4px 4px 0 0;
  text-align: center;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, color, text-shadow;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 14px;
  z-index: 2;
  background-color: var(--dark-1);

  &:not(.active) {
    cursor: pointer;
  }

  &:not(.active):hover,
  &.active {
    color: var(--stage-primary-color);
    border-color: var(--dark-2);
    border-bottom-color: transparent;
    text-shadow: 0 0 24px currentColor;
  }

  @include media("<tablet") {
    border-color: var(--dark-2) !important;
  }
}

.stepsWrapper {
  position: relative;
  top: -1px;
  min-height: 250px;
  padding: 28px 20px;
  border: 1px solid var(--dark-2);
  border-radius: 0 0 4px 4px;

  @include media("<tablet") {
    top: 0;
    margin-top: 12px;
  }
}

.stepItemWrapper {
  position: relative;
  display: flex;
  gap: 16px;
  padding: 12px 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    width: 1px;
    background: var(--grey-10);
    z-index: -1;
  }


  &:first-child:after {
    top: 12px;
  }

  &:last-child:after {
    bottom: calc(100% - 12px);
  }
}

.stepDot {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--dark-1);
  box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.2), inset 2px 4px 12px rgba(83, 129, 163, 0.22);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 50%;
  }
}

.stepTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}

.navLink {
  margin-top: 20px;
  height: 52px;

  @include media("<phone-lg") {
    width: 100%;
  }
}

.enter {
  opacity: 1;
}

.enterActive {
  opacity: 0.5;
  will-change: opacity;
  transition: 0.15s opacity ease-in-out;
}

.exit {
  opacity: 0;
}

.exitActive {
  opacity: 1;
  will-change: opacity;
  transition: 0.15s opacity ease-in-out;
}



