.container {
  background-color: var(--light-1);
  border-radius: 4px;
  overflow: hidden;
}

.blogPostsPaginationButton {
  --button-color: var(--grey-1);
  --button-hover-color: var(--orange-1);
  --button-border-color: var(--grey-2);
  --button-active-color: var(--orange-1);
}