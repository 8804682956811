.container {
  display: flex;
  flex: 1 0;
  min-height: calc(100vh - var(--header-height) - 260px);
}

.subContainer {
  position: relative;
  flex: 1 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.island {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 192px;
  height: 16px;
  z-index: 2;
  background: var(--dark-1);
  color: transparent;
  border-bottom: 1px solid currentColor;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: -1px;
    width: 16px;
    background: var(--dark-1);
    //background: white;
  }

  &:before {
    transform: skewX(30deg);
    left: -8px;
    border-left: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    border-radius: 0 0 0 4px;
  }

  &:after {
    transform: skewX(-30deg);
    right: -8px;
    border-right: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    border-radius: 0 0 4px 0;
  }
}