.figureSeparatorWrapper {
  position: relative;
  flex-grow: 1;
  color: var(--green-5);
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background: linear-gradient(90deg, rgba(21, 25, 34, 1) 0%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 65%, rgba(21, 25, 34, 1) 100%);
  }
}

.figureSeparator {
  position: relative;
  width: 100%;
  height: 28px;
  border-color: currentColor;
  border-top: 1px solid;
}

.bottom {
  transform: scale(-1);
}

.island {
  position: absolute;
  top: -1px;
  left: 50%;
  height: 28px;
  width: 64px;
  transform: translateX(-50%);
  background-color: var(--dark-1);

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
    background-color: var(--dark-1);
    border-bottom: 1px solid currentColor;
    z-index: 2;
  }

  &:before {
    left: -8px;
    transform: skew(30deg);
    border-left: 1px solid currentColor;
    border-bottom-left-radius: 4px;
  }

  &:after {
    right: -8px;
    transform: skew(-30deg);
    border-right: 1px solid currentColor;
    border-bottom-right-radius: 4px;

  }
}
