@import "../../../../styles/media-mixin";

.section {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 31px;
    width: 1px;
    background: linear-gradient(183.38deg, rgba(49, 53, 61, 0) 6.27%, #31353D 24.25%, rgba(49, 53, 61, 0.56) 63.17%, rgba(49, 53, 61, 0.35) 79.2%, #31353D 99.59%);
    z-index: -1;
  }

  &:not(:first-child) {
    padding-top: 80px;
  }

  @include media("<tablet") {
    &:after {
      display: none;
    }
  }

  @include media("<desktop-xlg") {
    &:not(:first-child) {
      padding-top: 64px;
    }
  }

  @include media("<phone-lg") {
    &:not(:first-child) {
      padding-top: 40px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  height: 64px;

  @include media("<phone-lg") {
    height: 32px;
    margin-bottom: 32px;
  }
}

.headerIcon {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--stage-primary-color);
  height: 62px;
  width: 62px;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  background: #161b24;

  &:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background: radial-gradient(60.72% 76.04% at 1.47% 0%, rgba(58, 68, 89, 0.8) 0%, rgba(58, 68, 89, 0) 100%);
    z-index: -1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    width: 0;
    border-radius: 50%;
    box-shadow: 0 0 24px 8px currentColor;
    z-index: 1;
  }

  & svg {
    position: relative;
    z-index: 2;
  }

  @include media("<tablet") {
    height: 40px;
    width: 40px;

    &:after {
      height: 40px;
      width: 40px;
    }

    &:before {
      box-shadow: 0 0 24px 5px currentColor;
    }

    & svg {
      height: 16px;
      width: 16px;
    }
  }

  @include media("<phone-lg") {
    height: 32px;
    width: 32px;

    &:after {
      height: 32px;
      width: 32px;
    }

    &:before {
      box-shadow: 0 0 24px 5px currentColor;
    }

    & svg {
      height: 13px;
      width: 13px;
    }
  }
}

.headerTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-transform: uppercase;
  color: var(--white);
  margin-left: 28px;

  @include media("<phone-lg") {
    font-size: 18px;
    line-height: 24px;
    margin-left: 16px;
  }
}

.headerLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 40px;
  margin: 0 0 auto auto;
  border: 1px solid #30343C;
  color: var(--stage-primary-color);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-shadow: 0 0 24px currentColor;
  overflow: hidden;

  @include media("<phone-lg") {
    height: 32px;
  }
}

.stage {
  display: flex;
  width: 100%;
}

.contentLine {
  width: 92px;
  flex-shrink: 0;

  @include media("<tablet") {
    display: none;
  }
}

.image {
  width: 100%;
}

.comment {
  display: flex;
  align-items: flex-start;
  padding-bottom: 16px;
}

.commentAuthor {
  display: block;
  flex-shrink: 0;
  width: 72px;
  @include media("<phone-lg") {
    width: 40px;
  }
}

.commentText {
  flex: 1;
  margin-left: 24px;
  max-width: 600px;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: var(--white);

  & :global(.comment-accent) {
    font-weight: 700;
    color: var(--stage-primary-color);
  }

  @include media("<tablet") {
    font-size: 24px;
    line-height: 32px;
    max-width: 400px;
  }

  @include media("<phone-lg") {
    font-size: 18px;
    line-height: 24px;
    margin-left: 8px;
  }
}

.caption {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0 16px;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background: linear-gradient(270deg, rgba(49, 53, 61, 0) 0%, rgba(49, 53, 61, 0.64) 49.57%, rgba(49, 53, 61, 0) 100%);
  }
}

.captionText {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-4);

  @include media("<tablet") {
    font-size: 12px;
  }
}

.captionIcon {
  position: relative;
  color: var(--stage-primary-color);
  margin-right: 36px;
  height: 16px;
  width: 32px;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 10px;
    transform: translateX(-50%);
    height: 0;
    box-shadow: 0 0 24px 3px var(--stage-primary-color);
  }

  @include media("<tablet") {
    width: 22px;
    &:before {
      box-shadow: 0 0 24px 2px var(--stage-primary-color);
    }
    &:before {
      width: 4px;
    }

    & svg {
      width: 22px;
    }
  }
}

.step {
  position: relative;
  padding: 20px 20px 20px 92px;
  border: 1px solid #30343c;
  border-radius: 4px;
  background: linear-gradient(270deg, rgba(48, 52, 60, 0) 0%, rgba(48, 52, 60, 0.12) 71.35%, rgba(48, 52, 60, 0) 100%), #151922;

  @include media("<tablet-lg") {
    padding-left: 80px;
  }

  @include media("<tablet") {
    padding-left: 62px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:before {
    position: absolute;
    content: "";
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: rgba(58, 68, 89, 0.24);
    box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.1), inset 2px 4px 12px rgba(83, 129, 163, 0.12);
  }

  &:after {
    position: absolute;
    content: "";
    left: 27px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: var(--stage-primary-color);
    box-shadow: 0 0 24px 0 var(--stage-primary-color);
  }
}

.stepText {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
