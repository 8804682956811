@import "../../../../../styles/media-mixin.scss";

.reviewModalWrapper {
  overflow: hidden scroll;
  max-height: 100vh;
  width: 100vw;
  padding: 24px 24px 48px;

  @include media("<tablet") {
    padding: 16px;
  }
}

.reviewModalInnerWrapper {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  max-width: 1020px;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
}


.closeIconWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 64px;
}

.closeIcon {
  width: 56px;
  height: 56px;
  transition: 0.25s box-shadow ease-in-out;

  &:hover {
    box-shadow: inset 0 0 8px 8px rgba(84, 97, 125, 0.1);
  }
}

//=====*****=====//

.emptyCaseWrapper {
  height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  &:after,
  &:before {
    content: "";
    flex: 1;
    height: 1px;
    background-color: var(--dark-1);
  }
}

.emptyCaseTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-1);
  padding: 12px;
}

.buttonPrev,
.buttonNext {
  position: absolute;
  top: 142px;
  z-index: 1;
  background-color: var(--dark-1);
  width: 56px;
  height: 56px;
  border-color: var(--dark-3);
  padding: 0;
  transition: 0.25s color ease-in-out;

  @include media("<tablet") {
    display: none;
  }

  &:hover {
    color: var(--white);
  }
}

.buttonPrev {
  left: 0;
  transform: translateX(-50%);
}

.buttonNext {
  right: 0;
  transform: translateX(50%);
}

.dotsWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @include media("<tablet") {
    display: flex;
  }

}

.dot {
  width: 16px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--dark-1);
  border: 2px solid var(--grey-10);
  transition: 0.25s background-color ease-in-out, 0.25s border-color ease-in-out;
  z-index: 2;

  &:not(.activeDot) {
    &:hover {
      cursor: pointer;
      border-color: var(--green-2);
    }
  }
}

.activeDot {
  background-color: var(--green-2);
  border-color: var(--green-2-25);
}


.pagination {
  position: fixed !important;
  top: 16px !important;
  left: 50% !important;
  bottom: unset !important;
  transform: translateX(-50%) !important;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @include media("<tablet") {
    display: flex;
  }
}

.swiper {
  overflow: unset !important;
  padding: 0 16px !important;

  @include media("<tablet") {
    padding: 0 !important;
  }
}

