@import "../../../../../../../styles/media-mixin";

.bigPostStatistics {
  display: flex;
  align-items: center;
  color: var(--grey-1);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  gap: 48px;

  @include media("<desktop-xlg") {
    gap: 32px;
  }

  @include media("<tablet-lg") {
    gap: 24px;
  }
}

.bigPostDate,
.bigPostViewsCount,
.bigPostTimeToRead {
  display: flex;
  align-items: center;
  line-height: 20px;

  svg {
    margin-right: 8px;
  }
}

.bigPostTimeToRead {

  @include media("<phone-lg") {
    display: none;
  }
}