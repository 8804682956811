.container {
  position: relative;
  flex: 1;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 1416px auto;
  overflow: unset;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: min(100%, 360px);
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #5c6b8d 20%, #5c6b8d 80%, rgba(255, 255, 255, 0) 100%);
  }
}

.island {
  top: -1px;
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}

.icon {
  margin: 24px 0;
}

.description {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: var(--grey-4);
  max-width: 410px;
}

.button {
  margin-top: 24px;
}
