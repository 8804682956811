@import "../../../../../styles/media-mixin";

.caseCardWrapper {
  display: flex;
  align-items: stretch;
  flex-shrink: 0;
  flex-grow: 1;
  text-decoration: none;
}

.default,
.alternate {
  gap: 4px;
}

.default {

  @include media("<tablet-lg") {
    cursor: pointer;
  }

  .caseCardImageSection {
    width: 644px;

    @include media("<tablet") {
      max-width: 320px;
    }
  }

  .caseCardImageSection {
    flex-shrink: 0;
  }
}

.alternate {

  .caseCardContentWrapper {

    @include media("<tablet") {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-around;
      gap: 16px;
    }

    @include media("<phone-lg") {
      flex-direction: column;
    }

    .caseCardTitle,
    .caseCardDescription {
      flex: 1 1 50%;

      @include media("<phone-lg") {
        max-width: 100%;
      }
    }
  }

  @include media("<tablet") {
    flex-direction: column;
  }
}

.secondary {
  flex-direction: column;
  gap: 12px;
}

.caseCardImageSection {
  min-width: 320px;
  height: 468px;
  flex-grow: 1;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--dark-3);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.caseCardSecondaryImage {
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.caseCardSecondaryTextBlockWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--dark-2);
  overflow: hidden;
}

.caseCardSecondaryTextWrapper {
  flex-shrink: 0;
  padding: 20px;
}

.caseCardSecondaryTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--white);
}

.caseCardSecondaryDescription {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-1);
}

.caseCardSecondaryTagsWrapper {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.hiddenTags {
  max-height: 0;
  overflow: hidden;
}

.caseCardSecondaryTagsInnerWrapper {
}

.caseCardSecondaryTagsInnerContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  min-width: max-content;
}

.caseCardSecondaryTag {
  //flex-shrink: 0;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--grey-1);
  border-radius: 4px;
  border: 1px solid var(--dark-2);
  white-space: nowrap;
}

.tagsWrapper {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 1;

  @include media("<tablet") {
    max-width: 200px;
    flex-wrap: wrap;
  }
}

.tag {
  padding: 4px 12px;
  background: var(--dark-1);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
}

.caseCardContentSection {
  min-width: 324px;
  flex: 1;
  border-radius: 4px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;

}

.caseCardCaption {
  padding: 20px;
  border-bottom: 1px solid var(--grey-5);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-1);
}

.caseCardContentWrapper {
  flex-grow: 1;
  padding: 32px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.caseCardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--dark-1);
}

.caseCardDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-1);
}

.caseCardButtonWrapper {
  padding: 20px;
}

.caseCardButton {
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  height: 56px;
}
