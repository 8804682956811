.memberCardWrapper {
  position: relative;
  width: 186px;
  height: 116px;
  padding: 0 8px;
  overflow: hidden;
  border: 2px solid var(--dark-5);
  border-radius: 4px;
  transition: 0.25s border-color ease-in-out;
  cursor: pointer;
  background-color: var(--dark-1);
  background-repeat: no-repeat;
  flex-shrink: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180.06deg, rgba(21, 25, 34, 0.64) 0.05%, rgba(21, 25, 34, 0) 45.98%);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.memberCardWrapper:hover,
.active {
  border-color: var(--blue-2);
}

.active {
  .memberCardImage {
    transform: scale(1.1) translateY(16px);
  }
}

.memberCardWrapper:hover:not(.active) {
  .memberCardImage {
    transform: scale(1) translateY(16px);
  }
}

.memberCardImage {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: 0.5s transform ease-in-out;
  transform: scale(0.9);
}

.memberCardTextWrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.memberCardName {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: var(--white)
}

.memberCardRole {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: var(--grey-4)
}
