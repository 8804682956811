@import "../../../../styles/media-mixin";

.title {
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  margin-top: 64px;

  @include media("<phone-lg") {
    margin-top: 40px;
  }
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-4);
  text-align: center;
  margin-top: 12px;
}

.info {
  display: flex;
  margin-top: 44px;
  padding: 20px;
  @include media("<tablet-lg") {
    flex-wrap: wrap;
  }

  @include media("<phone-lg") {
    margin-top: 40px;
  }
}

.authors {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  @include media("<tablet-lg") {
    justify-content: center;
    width: 100%;
    margin-bottom: 48px;
    flex: unset;
  }

  @include media("<tablet") {
    order: 1;
    margin-bottom: 28px;
  }
}

.author {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 2px solid var(--dark-1);
  overflow: hidden;
  &:not(:last-child) {
    margin-right: -16px;
  }
}

.authorImg {
  width: 100%;
  height: 100%;
}

.tags {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  gap: 4px;

  @include media("<tablet-lg") {
    flex: unset;
  }

  @include media("<tablet") {
    order: 3;
    width: 100%;
    flex-wrap: wrap;
  }

  @include media("<phone-lg") {
    flex-direction: column;
  }
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 8px 16px;
  border: 1px solid var(--dark-2);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--grey-4);
  white-space: nowrap;
  min-width: max-content;

  @include media("<tablet") {
    flex: 1;
  }
}

.date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 38px;
  align-self: flex-end;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: var(--grey-7);

  & svg {
    margin-right: 8px;
  }

  @include media("<tablet") {
    justify-content: center;
    width: 100%;
    order: 2;
    flex: unset;
    margin-bottom: 28px;
  }

  @include media("<phone-lg") {
    margin-bottom: 32px;
  }
}
