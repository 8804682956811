@import "../../styles/media-mixin.scss";

.footer {
  max-width: 1440px;
  margin-top: auto;
  padding: 0 12px;

  @include media("<phone-lg") {
    padding: 0 6px;
  }
}

.footerInner {
  margin: 68px 0 8px;
  position: relative;
  max-width: inherit;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #30343C; // не забути повернути змінну var(--dark-2)
  border-radius: 12px;

  @include media("<tablet") {
    padding: 16px 16px 24px;
    margin-bottom: 28px;
  }

  @include media("<phone-lg") {
    padding: 12px 12px 20px;
    margin-bottom: 28px;
  }
}

.logoWrapper {
  position: absolute;
  padding: 0 36px 16px;
  left: 50%;
  top: -1px;
  transform: translateX(-50%);
  background-color: var(--dark-1);
  border-bottom: 1px solid var(--dark-2);

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: -1px;
    background-color: inherit;
    width: 24px;
    border-bottom: 1px solid var(--dark-2);

  }

  &:before {
    left: -16px;
    transform: skew(20deg);
    border-left: 1px solid var(--dark-2);
    border-bottom-left-radius: 4px;
  }

  &:after {
    right: -16px;
    transform: skew(-20deg);
    border-right: 1px solid var(--dark-2);
    border-bottom-right-radius: 4px;
  }
}

.privacyPolicyText {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: var(--grey-4);

  @include media("<tablet") {
    bottom: -16px;
  }
}

.leftSideSection,
.rightSideSection {
  display: flex;
  align-items: center;
}

.rightSideSection {
  flex-direction: row-reverse;
}

.linkButton {
  text-shadow: 0 0 24px currentColor;
  flex-shrink: 0;
}

.behanceBGIcon,
.dribbleBGIcon,
.linkedInBGIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.behanceBGIcon {
  left: -32px;
}

.dribbleBGIcon {
  left: -46px;
  transform: translateY(-50%) rotate(-4deg);
}

.linkedInBGIcon {
  left: -48px;
  transform: translateY(-50%) rotate(2deg);
}

.socialLogoIcon {
  flex-shrink: 0;
}

.buttonContentWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ratingWrapper {
  display: inline-flex;
  align-items: center;
}

.separator {
  width: 1px;
  height: 16px;
  background: var(--dark-2);
}

.ratingValue {
  margin-left: 8px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white);
}

.island {
  position: absolute;
  left: 50%;
  bottom: -1px;
  width: 180px;
  height: 10px;
  transform: translateX(-50%);
  border-top: 1px solid var(--dark-2);
  background-color: var(--dark-1);

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 32px;
    background-color: inherit;
    border-top: 1px solid var(--dark-2);

  }

  &:before {
    left: -24px;
    transform: skew(-30deg);
    border-left: 1px solid var(--dark-2);
    border-top-left-radius: 4px;
  }

  &:after {
    right: -24px;
    transform: skew(30deg);
    border-right: 1px solid var(--dark-2);
    border-top-right-radius: 4px;
  }
}


