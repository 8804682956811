@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-ThinIt.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-ExtrabldIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
