.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: var(--light-1);
  margin-bottom: 12px;
  transition: 0.25s height ease-in-out, 0.25s margin-bottom ease-in-out, 0.25s padding-top ease-in-out;
}

.navBarWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  position: sticky;
  top: 0;
  background-color: var(--dark-1);
  transition: 0.25s height ease-in-out;
  overflow-Y: hidden;
  padding: 12px;
  gap: 12px;
}