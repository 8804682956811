.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--dark-1);
  border: 1px solid var(--dark-2);
  color: var(--white);
  cursor: pointer;
  padding: 0;

  &.enabledPadding {
    padding: 8px;
  }

  &.large {
    height: 80px;
    width: 80px;
  }
}
