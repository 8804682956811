@import "../../../../../styles/media-mixin";

.storyWrapper {
  position: relative;
  min-width: 320px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 9/16;

  @include media("<desktop-xlg") {
    min-width: 280px;
  }

  @include media("<tablet-lg") {
    min-width: 320px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    transition: 0.25s top ease-in-out;
  }


  &:hover {
    &:after {
      top: 0;
      background: linear-gradient(180deg, rgba(61, 253, 149, 0.4) 0%, rgba(21, 25, 34, 0) 100%);
    }

    .playBigButton,
    .playBigIcon {
      transform: translate(-50%, -50%) scale(1)
    }

    .playBigButton {
      background-color: var(--green-2);
    }

    .playBigIcon {
      border-color: transparent transparent transparent var(--black);
    }

    .storyTitleWrapper {
      background-color: var(--green-2);
    }

    .playIcon {
      border-color: transparent transparent transparent var(--green-2);
    }
  }

}

.fixedSize {
  width: calc(576px * 720 / 1280);
  height: 576px;
}

.storyImage {
  display: block;
  height: 100%;
  width: auto;
  object-fit: cover;
}

.storyTitleWrapper {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  background-color: var(--white);
  border-radius: 12px;
  transition: 0.25s background-color ease-in-out, 0.25s transform ease-in-out;
}

.progressBar {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  height: 4px;
  border-radius: 4px;
  background-color: var(--white);
  transform: scale(0);
  transition: 0.25s transform ease-in-out;
}

.progressBarLine {
  width: 0;
  height: 100%;
  background-color: var(--green-2);
  border-radius: 4px;
  transition: 0.25s width ease-in-out;
}

.storyTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-1);
  max-width: 220px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
}

.playBigButton {
  position: absolute;
  display: inline-block;
  width: 88px;
  height: 88px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-color: transparent;
  border-radius: 50%;
  transition: 0.25s transform ease-in-out, 0.25s background-color ease-in-out;
  z-index: 1;
}

.playBigIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 13px 0 13px 22px;
  border-color: transparent;
  transition: 0.25s transform ease-in-out, 0.25s border-color ease-in-out;
  margin-left: 1px;
  z-index: 2;
}

.playButton {
  flex-shrink: 0;
  background-color: var(--dark-1);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: relative;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent var(--white);
  transition: 0.25s border-color ease-in-out;
  margin-left: 1px;
}

.storyAuthor {
  position: absolute;
  left: 12px;
  bottom: 12px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-1);
  background-color: var(--white);
  border-radius: 12px;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
}