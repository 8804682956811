@import "../../styles/media-mixin";

.root {
  display: flex;
  background-color: var(--dark-1);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-grow: 1;
  max-width: 1440px;
  margin: auto;

  & > .footer {
    display: none;
  }

  @include media("<desktop-xlg") {
    max-width: 992px;
  }

  @include media("<tablet-lg") {
    flex-direction: column;
    padding: 0 0 100px;

    & > .footer {
      display: flex;
      margin-top: 12px;
    }
  }
}

.mainSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px 48px 20px 20px;

  .mainSectionInner {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .team {
    display: flex;
  }

  .teamItem {
    position: relative;
    background-color: var(--dark-1);
    width: 88px;
    height: 88px;
    padding: 4px;
    margin-right: -16px;
    border-radius: 50%;

    &:first-child {
      z-index: 2;
    }

    &:nth-child(2) {
      z-index: 1;
    }

    &:last-child {
      margin-right: 0;
    }

    & img {
      height: auto;
      width: 100%;
    }

    &:after {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      background-color: var(--green-2);
      border: 4px solid var(--dark-1);
      border-radius: 50%;
      right: 1px;
      bottom: 1px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeIcon {
      position: absolute;
      display: none;
      width: 56px;
      height: 56px;
      top: 12px;
      right: 12px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .headerText {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: var(--grey-6);
    text-shadow: 0 0 40px rgba(61, 253, 149, 0.24);
    text-align: right;

    span {
      color: var(--white);
      font-weight: 700;
    }
  }

  .content {
    padding: 24px 0;
    font-weight: 400;
    font-size: 55px;
    line-height: 72px;
    text-transform: uppercase;
    color: var(--white);
    text-shadow: 0 0 40px rgba(61, 253, 149, 0.24);

    @include media("<phone-lg") {
      padding: 20px 0 16px;
    }

    .contentAccent {
      font-weight: 700;
      color: var(--green-2);
    }
  }

  .socialsMobile {
    display: none;
    padding-bottom: 24px;

    @include media("<tablet-lg") {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    @include media("<phone-lg") {
      flex-direction: row;
      padding-bottom: 4px;
    }
  }

  @include media("<desktop-xlg") {
    padding-right: 10px;

    .teamItem:nth-child(3) {
      display: none;
    }

    .content {
      font-size: 36px;
      line-height: 48px;
    }
  }

  @include media("<tablet-lg") {
    padding: 12px;
    flex: unset;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;

    .mainSectionInner {
      max-width: 404px;
    }

    .header {
      justify-content: flex-start;

      .closeIcon {
        display: flex;
      }
    }

    .headerText {
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
      text-align: left;
    }

    .footer {
      display: none;
    }

    .teamItem:nth-child(3) {
      display: block;
    }

    .teamItem {
      width: 56px;
      height: 56px;
      margin-right: -10px;

      &:after {
        width: 11px;
        height: 11px;
      }
    }

    .content {
      font-size: 32px;
      line-height: 36px;
    }
  }

  @include media("<phone-lg") {
    flex-direction: column;
    align-items: flex-start;

    .mainSectionInner {
      max-width: 336px;
    }

    .teamItem:not(:first-child) {
      display: none;
    }

    .teamItem:first-child {
      margin-right: 0;
    }

    .content {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-radius: 4px;
  background-color: var(--dark-6);
  padding: 24px;

  .footerText {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: var(--grey-6);
  }

  .footerContact {
    display: flex;
    align-items: center;
    color: var(--green-2);
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-left: 8px;
    text-decoration: none;
    transition: 0.25s text-shadow ease-in-out;
    gap: 10px;

    &:hover {
      text-shadow: 0 0 24px currentColor;
    }
  }

  @include media("<tablet-lg") {
    height: 40px;

    .footerText {
      font-size: 14px;
      line-height: 20px;
    }

    .footerEmail {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 592px;
  background: var(--white);

  .formSectionInner {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  @include media("<desktop-xlg") {
    width: 414px;
  }

  @include media("<tablet-lg") {
    width: 100%;
  }
}

.socialSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  width: 120px;
  padding: 20px;

  .socialSectionInner {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .social {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      height: 80px;
      width: 80px;
    }
  }

  @include media("<tablet-lg") {
    display: none;
  }
}

.closeIcon {
  transition: .25s background-color ease-in-out, .25s box-shadow ease-in-out;

  &:hover {
    background: rgba(58, 68, 89, 0.24);
    box-shadow: inset -2px -2px 8px rgb(84 97 125 / 10%), inset 2px 4px 12px rgb(83 129 163 / 12%);
  }
}
