.container {
  background-color: var(--dark-1);
  padding: 12px 32px 12px 12px;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
}

.burgerIcon {
  position: relative;
  width: 18px;
  height: 12px;

  .top,
  .middle,
  .bottom {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: var(--light-1);
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
  }

  .top {
    top: 0;
    transition-property: transform, top;
  }

  .middle {
    top: 50%;
    transform: translateY(-50%);
    transition-property: opacity;
    opacity: 1;
  }

  .bottom {
    bottom: 0;
    transition-property: transform, bottom;
  }
}

.burgerIconOpen {

  .top {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  .middle {
    opacity: 0;
  }

  .bottom {
    bottom: 50%;
    transform: translateY(50%) rotate(-45deg);
  }
}

.item {
  left: 0;
  transition: 0.15s left ease-in-out;
}

.itemActive {
  left: 20px;
}