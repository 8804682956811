.separator {
  .line {
    height: 1px;
  }

  &.light,
  &.dark {
    .line {
      background-color: var(--dark-3);
    }
  }

  &.padding-top-small {
    padding-top: 32px;
  }
  &.padding-top-medium {
    padding-top: 48px;
  }
  &.padding-top-large {
    padding-top: 64px;
  }

  &.padding-bottom-small {
    padding-bottom: 32px;
  }
  &.padding-bottom-medium {
    padding-bottom: 48px;
  }
  &.padding-bottom-large {
    padding-bottom: 64px;
  }
}
