@import "../../../styles/media-mixin";

.sectionContainer {
  margin-top: 64px;
}

.sectionTitle,
.sectionSubtitle {
  font-weight: 700;
  text-transform: uppercase;
}

.sectionTitle {
  max-width: 660px;
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 56px;
  color: var(--white);
  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 48px;
  }
}

.sectionSubtitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-4);
}
