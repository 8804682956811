@import "../../../../../styles/media-mixin";
.container {
  position: absolute;
  bottom: 54px;
  left: 50%;
  transform: translateX(calc(-50% - 2px));
  width: 229px;
  height: 197px;
}

.box {
  position: relative;

  img {
    display: block;
    width: 229px;
    height: 197px;
  }
}

.glass {
  position: absolute;
  top: 29px;
  left: 0;
  z-index: 2;
  img {
    display: block;
    width: 229px;
    height: 168px;
  }
}

.items {
  position: absolute;
  top: 39px;
  left: 0;
  width: 222px;
  height: 152px;
  overflow: hidden;
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: carousel 12s linear infinite;
  transform: translateX(100%);

  &:nth-child(1) {
    animation-name: first-picture, carousel;
    animation-duration: 12s;
    animation-iteration-count: 1, infinite;
    animation-delay: 0s, 12s;
    transform: translateX(0%);
  }
  &:nth-child(2) {
    animation-delay: Calc(12s * .25);
  }
  &:nth-child(3) {
    animation-delay: Calc(12s * .50);
  }
  &:nth-child(4) {
    animation-delay: Calc(12s * .75);
  }
}

@keyframes first-picture {
  0% { transform: translateX(0%); }
  5.5%, 25% { transform: translateX(0); }
  30.5%, 100% { transform: translateX(105%); }
}

@keyframes carousel {
  0% { transform: translateX(-100%); }
  5.5% { transform: translateX(5%); }
  7.0% { transform: translateX(0); }
  25% { transform: translateX(0); }
  30.5%, 100% { transform: translateX(105%); }
}

.itemImg {
  width: 222px;
  height: 152px;
}
