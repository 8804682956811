@import "../../../../../styles/media-mixin";

.container {
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  font-weight: 700;
  color: var(--dark-1);
  background: var(--light-2);
  transform: scale(0.5);
  opacity: 0;
  transition: 0.15s transform ease-in-out, 0.3s opacity ease-in-out, 0.25s background-color ease-out;
  cursor: pointer;

  &:hover {
    img {
      scale: 1.02;
    }
  }
}

img {
  transition: 0.45s scale ease-in-out;
}

.show {
  transform: scale(1);
  opacity: 1;
}


// *** SMALL POST *** //

.smallPostWrapper {
  flex-direction: column;
  transition: 0.25s background-color ease-out;

  &:not(.dark):hover {
    background-color: var(--light-6);
  }
}

.smallPostTag {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  padding: 8px 24px;
  background-color: var(--light-2);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--grey-1);
}

.smallPostImageContainer {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallPostImage {
  height: 256px;
  width: 100%;
  object-fit: cover;
}

.smallPostTitle {
  padding: 20px;
  height: 168px;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  max-width: 298px;
}

// *** MEDIUM POST *** //

.mediumPostWrapper {
  grid-column: span 2;

  &:not(.dark):hover {
    background-color: var(--light-6);
  }

  @include media("<desktop-lg") {
    grid-column: auto;
  }
}

.mediumPostTitleWrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  max-width: 50%;
}

.mediumPostTitleTag {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  padding: 8px 24px;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--grey-1);
  border-radius: 8px;
  border: 1px solid var(--grey-3);
}

.mediumPostTitle {
  font-size: 24px;
  line-height: 32px;
  max-width: 280px;
  text-transform: uppercase;
}

.mediumPostImageContainer {
  flex-grow: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mediumPostImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// *** BIG POST *** //

.bigPostWrapper {
  width: 100%;
  height: 600px;
  display: flex;
  overflow: hidden;
  background: var(--light-2);
  cursor: pointer;
  position: relative;

  @include media("<desktop-xlg") {
    height: 410px;
  }

  @include media("<tablet-lg") {
    height: 314px;
  }

  @include media("<tablet") {
    height: unset;
    flex-direction: column-reverse;
  }
}

.bigPostContentWrapper {
  min-width: 310px;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @include media("<phone-lg") {
    padding: 20px 12px;
  }

}

.bigPostTagsWrapper {
  display: flex;
  gap: 4px;

  @include media("<tablet") {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.bigPostTag {
  padding: 8px 24px;
  border: 1px solid var(--grey-3);
  border-radius: 8px;
  color: var(--grey-1);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;

  @include media("<tablet") {
    background-color: var(--light-2);
  }
}

.bigPostTitle {
  margin-top: 48px;
  max-width: 510px;
  color: var(--dark-1);
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-transform: uppercase;

  @include media("<desktop-xlg") {
    font-size: 28px;
    line-height: 40px;
    margin-top: 40px;
  }

  @include media("<tablet-lg") {
    margin-top: 20px;
    font-size: 24px;
    line-height: 32px;
  }

  @include media("<tablet") {
    margin-top: 0;
    font-size: 28px;
    line-height: 40px;
    max-width: 476px;
  }

  @include media("<phone-lg") {
    font-size: 24px;
    line-height: 32px;
  }
}

.bigPostDescription {
  margin-top: 24px;
  margin-bottom: auto;
  max-width: 606px;
  color: var(--grey-1);
  font-size: 20px;
  line-height: 34px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media("<desktop-xlg") {
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;

  }

  @include media("<tablet-lg") {
    margin-top: 12px;
  }


  @include media("<tablet") {
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 402px;
  }

  @include media("<phone-lg") {
    margin-top: 12px;
    margin-bottom: 24px;
  }
}

//.bigPostTitle,
//.bigPostDescription {
//  @include media("<tablet") {
//    max-width: 300px;
//  }
//}

.bigPostDate,
.bigPostViewsCount,
.bigPostTimeToRead {
  display: flex;
  align-items: center;
  line-height: 20px;

  svg {
    margin-right: 8px;
  }
}

.bigPostImageWrapper {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.bigPostMainImage {
  width: auto;
  height: 100%;
  object-fit: cover;
}


// *** DEFAULT POST *** //

.defaultPostWrapper {

  &:not(.dark):hover {
    background-color: var(--light-6);
  }

  @include media("<tablet") {
    flex-direction: column;
  }
}


.defaultPostImageContainer {
  flex-shrink: 0;
  height: 228px;
  width: 298px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include media("<tablet") {
    width: 100%;
    height: unset;
  }
}

.defaultPostImage {
  aspect-ratio: 504/386;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.defaultPostTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.defaultPostTitleTag {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--grey-1);
  border-radius: 8px;
  border: 1px solid var(--grey-3);
  padding: 8px 24px;

  @include media("<tablet") {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: var(--light-2);
    border: none;
  }
}

.defaultPostTitle {
  font-size: 24px;
  line-height: 32px;
  //max-width: 280px;
  text-transform: uppercase;
}


.dark {
  background-color: var(--dark-1);
  border: 1px solid var(--dark-3);

  .smallPostTitle,
  .mediumPostTitle,
  .defaultPostTitle {
    color: var(--light-1);
  }
}