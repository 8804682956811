@import "../../../../styles/media-mixin";

.section {
  //background:radial-gradient(50% 554.65% at 50% 50%, #12151B 0%, rgba(19, 22, 28, 0) 100%);

}

.principles {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 50px 0 32px;
}

.principlesLeftLine {
  flex: 1;
  height: 1px;
  background: linear-gradient(270deg, rgba(48, 52, 60, 0.5) 0%, rgba(114, 130, 166, 0) 0.01%, #7282A6 100%);
  transform: rotate(-180deg);
}

.principlesRightLine {
  flex: 1;
  height: 1px;
  background: linear-gradient(270deg, rgba(48, 52, 60, 0.5) 0%, rgba(114, 130, 166, 0) 0.01%, #7282A6 100%);
}

.principlesTitle {
  flex-shrink: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  padding: 0 16px;
  color: var(--grey-1);
}

.grid {
  padding-bottom: 80px;
}

.card {
  position: relative;
  background: radial-gradient(50% 32.83% at 50% 0%, rgba(156, 255, 219, 0.066) 0%, rgba(156, 255, 219, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  , radial-gradient(47.35% 88.01% at 52.65% 88.01%, rgba(61, 253, 184, 0.05) 0%, rgba(61, 253, 184, 0) 100%), linear-gradient(180deg, rgba(25, 31, 45, 0.3) 0%, #191F2D 26.51%, rgba(25, 31, 45, 0.04) 95.65%);
  border-radius: 4px;
  border: 1px solid var(--green-4);
  min-height: 230px;
  padding: 32px 23px 74px;
}

.cardTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-transform: uppercase;

  color: var(--green-gay);
  text-align: center;

}

.cardDescription {
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-green);
}

.cardImg {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 210px;
  z-index: 3;
}

.island {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 85px;
  height: 17px;
  z-index: 2;
  background: var(--dark-1);
  color: transparent;
  border-top: 1px solid var(--green-4);

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: -1px;
    bottom: 0;
    width: 44px;
    background: var(--dark-1);
    //background: white;
  }

  &:before {
    transform: skewX(-60deg);
    left: -18px;
    border-left: 2px solid var(--green-4);
    border-top: 1px solid var(--green-4);
    border-radius: 4px 0 0 0;
  }

  &:after {
    transform: skewX(60deg);
    right: -18px;
    border-right: 2px solid var(--green-4);
    border-top: 1px solid var(--green-4);
    border-radius: 0 4px 0 0;
  }
}
