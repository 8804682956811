@import "../../../../../../../styles/media-mixin";

.searchBar {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-top: 20px;

  & :not(:first-child) {
    margin-left: 4px;
  }

  @include media("<phone-lg") {
    border-bottom: 1px solid var(--grey-2);
    padding: 12px 0;
  }
}

.searchButton {
  margin-right: auto;


  @include media("<phone-lg") {
    border: none !important;
    width: auto !important;
    flex-shrink: 1 !important;
    min-width: 0 !important;
  }

}

.filterButtonsWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  @include media("<phone-lg") {
    max-width: 100%;
    overflow-x: scroll;
  }
}

.filterButton {

  &:not(.active):hover {
    color: var(--green-5);
    border-color: var(--green-5);
  }

  &.active {
    cursor: default;
  }

  @include media("<desktop-xlg") {
    flex-grow: 1;
  }

  @include media("<phone-lg") {
    flex-shrink: 0;

    &:first-child {
      margin-left: 12px;
    }

    &:last-child {
      margin-right: 12px;
    }
  }


}


