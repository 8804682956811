.contentLayout {
  position: relative;
}

.sidebarWrapper {
  margin-top: 280px;
  position: sticky;
  top: var(--header-height);
}

.contactUsBlock {
  margin-top: 64px;
}