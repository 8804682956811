@import "../../../../styles/media-mixin";

.section {
  padding-left: var(--padding-desktop);
  padding-right: var(--padding-desktop);

  @include media("<phone-lg") {
    padding-left: var(--padding-mobile);
    padding-right: var(--padding-mobile);
  }

  &.withoutPaddings {
    padding-left: var(--padding-desktop);
    padding-right: var(--padding-desktop);

    @include media("<phone-lg") {
      padding-left: var(--padding-mobile);
      padding-right: var(--padding-mobile);
    }

    //.header,
    //.blocks {
    //  padding-left: var(--padding-desktop);
    //  padding-right: var(--padding-desktop);
    //}

    .header {
      padding-left: var(--padding-desktop);
      padding-right: var(--padding-desktop);
    }

    @include media("<desktop-xlg") {
      &.view-desktop-xlg {
        padding-left: 0;
        padding-right: 0;

        .blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include media("<desktop-lg") {
      &.view-desktop-lg {
        padding-left: 0;
        padding-right: 0;

        .blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include media("<desktop") {
      &.view-desktop {
        padding-left: 0;
        padding-right: 0;

        .blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include media("<tablet-lg") {
      &.view-tablet-lg {
        padding-left: 0;
        padding-right: 0;

        .blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include media("<tablet") {
      &.view-tablet {
        padding-left: 0;
        padding-right: 0;

        .blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include media("<phone-lg") {
      &.view-phone-lg {
        padding-left: 0;
        padding-right: 0;

        .blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .header {
        padding-left: var(--padding-mobile);
        padding-right: var(--padding-mobile);
      }

      &:not(&.view-desktop-xlg):not(&.view-desktop-lg):not(&.view-desktop):not(&.view-tablet-lg):not(&.view-tablet):not(&.view-phone-lg) {
        .blocks {
          padding-left: var(--padding-mobile);
          padding-right: var(--padding-mobile);
        }
      }
    }
  }

  &:not(&.withoutPaddings) {
    padding-left: var(--padding-desktop);
    padding-right: var(--padding-desktop);

    @include media("<phone-lg") {
      padding-left: var(--padding-mobile);
      padding-right: var(--padding-mobile);
    }
  }
}

.container {
  border-radius: 4px;

  &:not(.withoutPaddings) {
    .blocksInner {
      padding: 16px;
    }
  }

  &:not(.withoutBackground) {
    background-color: var(--light-1);
    padding-top: 20px;
    padding-bottom: 40px;

    .title {
      color: var(--dark-1);
    }

    .header {
      padding-left: var(--padding-desktop);
      padding-right: var(--padding-desktop);
    }
  }

  &.border {
    .blocksInner {
      border: 1px solid var(--section-border-color);
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

.header {

}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-transform: uppercase;
  color: var(--white);
  margin-bottom: 20px;

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 48px;
  }
}

.centerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--grey-1);
  flex-shrink: 0;

  &:before,
  &:after {
    content: "";
    height: 1px;
    flex: 1 1;
  }

  &:before {
    margin-right: 16px;
    background: linear-gradient(270deg, currentColor 0%, rgba(114, 130, 166, 0) 100%);
  }

  &:after {
    margin-left: 16px;
    background: linear-gradient(90deg, currentColor 0%, rgba(114, 130, 166, 0) 100%);
  }
}

.caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-4);
  margin-bottom: 8px;
}

.description {
  max-width: 500px;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-1);
}

.image {
  width: 100%;
  height: auto;
}

.margin-top-x-small {
  margin-top: 16px;
}

.margin-top-small {
  margin-top: 32px;
}

.margin-top-medium {
  margin-top: 48px;
}

.margin-top-large {
  margin-top: 64px;
}

.margin-bottom-x-small {
  margin-bottom: 16px;
}

.margin-bottom-small {
  margin-bottom: 32px;
}

.margin-bottom-medium {
  margin-bottom: 48px;
}

.margin-bottom-large {
  margin-bottom: 64px;
}
