.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.title,
.icon {
  color: var(--grey-4);
}

.icon,
.breadCrumb {
  margin-left: 24px;
}

.title,
.breadCrumb {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.breadCrumb {
  max-width: 154px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--grey-4);
  overflow: hidden;

  &:not(.lastCrumb) {
    cursor: pointer;
  }
}

.lastCrumb {
  color: var(--dark-1);
}
