@import "../../../styles/media-mixin";

.container {
  .inner {
    display: flex;
    gap: 4px;
  }

  @include media("<desktop-xlg") {
    &.view-desktop-xlg {
      .inner,
      & + .blockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<desktop-lg") {
    &.view-desktop-lg {
      .inner,
      & + .blockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<desktop") {
    &.view-desktop {
      .inner,
      & + .blockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<tablet-lg") {
    &.view-tablet-lg {
      .inner,
      & + .blockActionsWrapper {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<tablet") {
    &.view-tablet {
      .inner {
        padding-left: var(--padding-desktop);
        padding-right: var(--padding-desktop);
      }
    }
  }

  @include media("<phone-lg") {
    &.view-desktop-xlg,
    &.view-desktop-lg,
    &.view-desktop,
    &.view-tablet-lg,
    &.view-tablet,
    &.view-phone-lg {
      .inner {
        padding-left: var(--padding-mobile);
        padding-right: var(--padding-mobile);
      }
    }
  }

  @include media("<tablet") {
    overflow-x: auto;
    padding-top: 88px;
    .inner {
      flex-wrap: nowrap;
      min-width: max-content;
    }
  }
}

.blockActionsWrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blockPaginationButton {
  --button-color: var(--grey-4);
  --button-hover-color: var(--green-2);
  --button-border-color: var(--dark-3);
  --button-active-color: var(--green-2);
}
