@import "../../../../styles/media-mixin.scss";

.homeHeaderSectionWrapper {
  position: relative;
  top: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 96px;
  border-radius: 4px;
  background-color: var(--dark-1);
  background-size: cover;
  background-position: center;

  @include media("<tablet") {
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: 50%;
    left: -1px;
    background: linear-gradient(180deg, #30343C 0%, rgba(48, 52, 60, 0) 100%);
    border-radius: 4px;
    z-index: -1;
  }
}

.homeHeaderSectionCaption {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  background: linear-gradient(92.62deg, #81B3DA 28.23%, #5ABCD1 70.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0 2px 0 #07091115;

  @include media("<tablet") {
    font-size: 18px;
    line-height: 24px;
  }

  @include media("<phone-lg") {
    font-size: 16px;
  }
}

.homeHeaderSectionTitle {
  margin-top: 24px;
  max-width: 940px;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  text-align: center;
  color: var(--white);

  @include media("<tablet-lg") {
    font-size: 56px;
    line-height: 72px;
  }

  @include media("<tablet") {
    font-size: 48px;
    line-height: 64px;
  }

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 48px;
  }
}

.homeHeaderSectionAnimationBlock {
  position: relative;
  margin-top: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  //animation: 10s baseAnimation infinite;

  @include media("<desktop-lg") {
    justify-content: space-between;
    gap: 16px;
  }

  @include media("<tablet") {
    flex-direction: column;
    margin-top: 0;
  }
}

.separatorLeft {
  order: 2;

  @include media("<desktop-lg") {
    opacity: 0;
    height: 0;
  }
}

.separatorRight {
  order: 4;

  @include media("<desktop-lg") {
    opacity: 0;
    height: 0;

  }
}

.homeHeaderSectionAnimationBlock {
  .boxOne,
  .boxTwo,
  .boxThree {

    @include media("<tablet") {
      width: 100%;
      height: unset;
    }
  }
}

.boxOne {
  order: 1;
}

.boxTwo {
  order: 3;
}

.boxThree {
  order: 5;
}


.boxIconWrapper {
  position: relative;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  color: var(--grey-9);
  border: 2px solid currentColor;
  border-radius: 8px;

  @include media("<tablet-lg") {
    width: 72px;
    height: 72px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 40px 16px currentColor;
    z-index: 1;
  }
}

.boxIcon {
  @include media("<tablet-lg") {
    height: 30px;
  }
}

.boxText {
  font-size: 28px;
  line-height: 40px;
  text-transform: uppercase;
  color: var(--white);
  white-space: nowrap;

  @include media("<tablet-lg") {
    font-size: 16px;
    line-height: 24px;
  }
}

.boxTitle {
  max-width: 290px;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: var(--white);

  @include media("<tablet-lg") {
    font-size: 24px;
  }
}

.boxDescription {
  max-width: 330px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--grey-1);
}

.light,
.boxOneLine,
.boxOneIconWrapper,
.separatorLeft,
.boxTwoMainLine,
.boxTwoSecondaryLine,
.separatorRight,
.boxThreeLine,
.boxThreeIconWrapper {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

.light {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: var(--light-4);
  background-color: currentColor;
  animation-name: lightAnimation;
  opacity: 0;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, rgba(21, 25, 34, 0) 0%, rgba(0, 255, 163, 0.24) 50.35%, rgba(21, 25, 34, 0) 100%);
    width: 1px;
    height: 68px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 24px 6px currentColor;
  }

  @include media("<tablet") {
    left: 50%;
    top: 10%;
    width: 6px;
    height: 6px;
    transform: translateX(-50%);
    animation-name: lightAnimationVertical;

    &:before {
      box-shadow: 0 0 24px 8px currentColor;
    }

    &:after {
      background: linear-gradient(270deg, rgba(21, 25, 34, 0) 0%, rgba(0, 255, 163, 0.24) 50.35%, rgba(21, 25, 34, 0) 100%);
      width: 68px;
      height: 1px;
    }
  }
}

.boxOneLine {
  animation-name: boxOneLineAnimation;
}

.boxOneIconWrapper {
  animation-name: boxOneIconAnimation;
}

.separatorLeft {
  animation-name: leftSeparatorAnimation;
}

.boxTwoMainLine {
  animation-name: boxTwoMainLineAnimation;
}

.boxTwoSecondaryLine {
  animation-name: boxTwoSecondaryLineAnimation;
}

.separatorRight {
  animation-name: rightSeparatorAnimation;
}

.boxThreeLine {
  animation-name: boxThreeLineAnimation;
}

.boxThreeIconWrapper {
  animation-name: boxThreeIconAnimation;
}

@keyframes lightAnimation {
  0% {
    left: 10%;
  }

  5% {
    opacity: 1;
  }

  30% {
    left: 40%;
  }

  35% {
    left: 60%;
  }

  65% {
    left: 90%;
    opacity: 1;
  }

  70% {
    opacity: 0;
    left: 90%;
  }
}

@keyframes lightAnimationVertical {
  0% {
    top: 10%;
    z-index: 1;
  }

  19% {
    opacity: 0;
  }
  21% {
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0;
  }

  30% {
    top: 40%;
  }

  35% {
    top: 60%;
  }

  48% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  57% {
    opacity: 0;
  }

  70% {
    top: 90%;
  }
}

@keyframes boxOneIconAnimation {
  10%, 90% {
    color: var(--grey-9);
  }

  15%, 85% {
    color: var(--green-2);
  }
}

@keyframes boxOneLineAnimation {
  10%, 90% {
    background-color: var(--green-5);
    box-shadow: unset;
  }

  15%, 85% {
    background-color: var(--light-4);
    box-shadow: 0 0 18px 4px rgba(61, 253, 184, 0.5), inset 0 0 4px rgba(61, 253, 184, 0.5), inset 0 0 2px var(--green-gay);
  }
}

@keyframes leftSeparatorAnimation {
  15%, 90% {
    color: var(--green-5);
  }

  20%, 85% {
    color: var(--green-2);
  }
}

@keyframes boxTwoMainLineAnimation {
  25%, 90% {
    background-color: var(--green-5);
    box-shadow: unset;
  }

  30%, 85% {
    background-color: var(--light-4);
    box-shadow: 0 0 18px 4px rgba(61, 253, 184, 0.5), inset 0 0 4px rgba(61, 253, 184, 0.5), inset 0 0 2px var(--green-gay);
  }
}

@keyframes boxTwoSecondaryLineAnimation {
  40%, 90% {
    background-color: var(--green-5);
    box-shadow: unset;
  }

  45%, 85% {
    background-color: var(--light-4);
    box-shadow: 0 0 18px 4px rgba(61, 253, 184, 0.5), inset 0 0 4px rgba(61, 253, 184, 0.5), inset 0 0 2px var(--green-gay);
  }
}

@keyframes rightSeparatorAnimation {
  45%, 90% {
    color: var(--green-5);
  }

  50%, 85% {
    color: var(--green-2);
  }
}

@keyframes boxThreeLineAnimation {
  58%, 90% {
    background-color: var(--green-5);
    box-shadow: unset;
  }

  63%, 85% {
    background-color: var(--light-4);
    box-shadow: 0 0 18px 4px rgba(61, 253, 184, 0.5), inset 0 0 4px rgba(61, 253, 184, 0.5), inset 0 0 2px var(--green-gay);
  }
}

@keyframes boxThreeIconAnimation {
  58%, 90% {
    color: var(--grey-9);
  }

  63%, 85% {
    color: var(--green-2);
  }
}
