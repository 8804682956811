@import "fonts.scss";
@import "colors.scss";
@import "variables.scss";
@import "z-index.scss";
@import "constants.scss";


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0;
}

html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  position: relative;
  display: flex;
  flex-grow: 1;
  background: var(--dark-1);
  overflow-y: scroll;
  cursor: default;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::placeholder,
  button,
  input,
  textarea {
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    font-style: normal;
  }

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: var(--scroll-width);
    height: var(--scroll-width);

    &-track {
      background: transparent;
    }

    &-thumb {
      background: var(--grey-1);
      border-radius: 4px;

      &:hover,
      &:active {
        background: var(--grey-1);
      }
    }
  }
}

.modalOpen {
  overflow: hidden;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 12px;
  width: 100%;
  overflow-x: clip;
  max-width: 1440px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.fade-enter {
  opacity: 1;
}

.fade-enter-active {
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.6s ease-in-out;
}

.fade-exit {
  opacity: 0;
}

.fade-exit-active {
  opacity: 1;
  will-change: opacity;
  transition: opacity 0.6s ease-in-out;
}

.fade-short-enter {
  opacity: 1;
}

.fade-short-enter-active {
  opacity: 0;
  will-change: opacity;
  transition: 0.25s opacity ease-in-out;
}

.fade-short-exit {
  opacity: 0.5;
}

.fade-short-exit-active {
  opacity: 1;
  will-change: opacity;
  transition: 0.25s opacity ease-in-out;
}

.transform-scale-enter {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.transform-scale-enter-active {
  opacity: 0;
  transform: scale(0.7) translateY(100px);
  will-change: transform, opacity;
  transition: transform 0.6s ease-in-out, opacity 0.3s ease-in-out;
}

.transform-scale-exit {
  opacity: 0;
  transform: scale(0.7) translateY(100px);
}

.transform-scale-exit-active {
  transform: scale(1) translateY(0);
  opacity: 1;
  will-change: transform, opacity;
  transition: transform 0.6s ease-in-out, opacity 0.3s ease-in-out;
}

.transform-x-enter {
  transform: translateX(0);
  opacity: 1;
}

.transform-x-enter-active {
  transform: translateX(-2000px);
  opacity: 0;
  will-change: transform, opacity;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.transform-x-exit {
  transform: translateX(-2000px);
  opacity: 0;
}

.transform-x-exit-active {
  transform: translateX(0);
  opacity: 1;
  will-change: transform, opacity;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.transform-x-50-enter {
  transform: translateX(0);
  opacity: 1;
}

.transform-x-50-enter-active {
  transform: translateX(-2000px);
  opacity: 0;
  will-change: transform, opacity;
  //transition-delay: 0.1s;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.transform-x-50-exit {
  transform: translateX(-2000px);
  opacity: 0;
}

.transform-x-50-exit-active {
  transform: translateX(0);
  opacity: 1;
  will-change: transform, opacity;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.transform-x-100-enter {
  transform: translateX(0);
  opacity: 1;
}

.transform-x-100-enter-active {
  transform: translateX(-2000px);
  opacity: 0;
  will-change: transform, opacity;
  //transition-delay: 0.2s;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.transform-x-100-exit {
  transform: translateX(-2000px);
  opacity: 0;
}

.transform-x-100-exit-active {
  transform: translateX(0);
  opacity: 1;
  will-change: transform, opacity;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.fade-page-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-page-enter.fade-page-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-page-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-page-exit.fade-page-exit-active {
  opacity: 0;
  transform: translate(0, 30px);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.hiddenScroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
