@import "../../../../styles/media-mixin";

.container {
  border-radius: 4px;
  background: var(--light-1);
  display: flex;
  justify-content: center;
  padding: 20px;
  color: var(--dark-1);
  //background-size: auto 100%;
  //background-position: 20px;
  //background-repeat: no-repeat;
  //background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftSection {
  flex-grow: 1;
}

.contentSection {
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.rightSection {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: flex-end;


  .image {
    position: relative;
    bottom: 112px;
  }
}

.image {
  width: 110px;
  height: auto;
}

.postTitle {
  margin-top: 48px;
  max-width: 920px;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  text-align: center;
  text-transform: uppercase;

  @include media("<tablet") {
    font-size: 40px;
    line-height: 48px;
    text-align: start;
  }
}

.postText {
  margin-top: 40px;
  max-width: 566px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-1);
  text-align: center;

  @include media("<tablet") {
    text-align: start;
  }
}

.authorContainer {
  margin-top: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.authorName {
  margin-top: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
}

.authorRole {
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-1);
  text-align: center;
}

.postInfoWrapper {
  padding: 0 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-1-12);
}

.tagsInfo {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--light-1);

  @include media("<phone-lg") {
    display: none;
  }
}