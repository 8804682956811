.mobileEndItemWrapper {
  padding: 64px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-3);
  min-width: 320px;
  background-size: cover;
}

.mobileEndItemIconWrapper {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--green-2);
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(58, 68, 89, 0.24), rgba(58, 68, 89, 0.24)), #151922;
  box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.1), inset 2px 4px 12px rgba(83, 129, 163, 0.12);
}

.mobileEndItemTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--white);
}

.mobileEndItemDescription {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--grey-1);
}

.mobileEndItemButton {
  width: 100%;
  font-size: 16px;
  color: var(--grey-4);

  &:hover {
    color: var(--green-2);
  }
}
