@import "../../../../styles/media-mixin";

.container {
  .successContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 654px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 20px;
  }

  .successInner {
    margin: auto;
  }

  .successTitle {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: var(--dark-1);
    max-width: 530px;
    margin: 0 auto;
    padding-top: 100px;

    span {
      font-weight: 700;
    }
  }

  .successDescription {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--grey-1);
    max-width: 410px;
    margin: 32px auto 0;
  }

  @include media("<tablet-lg") {
    .successContainer {
      height: 448px;
    }

    .successTitle {
      font-size: 28px;
      line-height: 36px;
    }

    .successDescription {
      font-size: 16px;
      line-height: 24px;
      margin: 24px auto 0;
    }
  }

  @include media("<desktop-xlg") {
    .successContainer {
      padding: 16px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  @include media("<desktop-xlg") {
    padding: 16px;
  }
}

.fieldWrapper {
  position: relative;
  margin-bottom: 30px;
  width: 100%;

  .fieldInner {
    width: 100%;
    overflow: hidden;
  }

  .fieldIcon {
    position: absolute;
    right: 16px;
    bottom: 24px;
    width: 24px;
    height: 24px;
    color: var(--red-4);
    border-radius: 50%;
    z-index: 100;
    cursor: pointer;

    svg {
      height: inherit;
      width: inherit;
    }

    &:before {
      position: absolute;
      content: "";
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: var(--white);
      z-index: -1;
    }

    &:not(&.mobile):hover {
      .tooltip {
        opacity: 1;
      }
    }

    &.mobile {
      &.showError {
        .tooltip {
          opacity: 1;
        }
      }
    }
  }

  .bg,
  .hoverBg,
  .focusBg,
  .invalidBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: .25s opacity ease-in-out;
  }

  .bg {
    background: radial-gradient(100% 100% at 50% 100%, #F7F9FC 0%, rgba(247, 249, 252, 0.25) 100%), radial-gradient(100% 100% at 50% 100%, #F7F9FC 0%, rgba(247, 249, 252, 0.25) 100%);
    border-bottom: 1px solid var(--grey-5);
    opacity: 1;
    z-index: 0;
  }

  .hoverBg {
    background: radial-gradient(100% 100% at 50% 100%, #EDF1F7 0%, rgba(237, 241, 247, 0.25) 100%);
    border-bottom: 1px solid #8A91A8;
    z-index: 0;
  }

  .focusBg {
    background: radial-gradient(100% 100% at 50% 100%, #E9FDF2 0%, rgba(233, 253, 242, 0.25) 100%);
    border-bottom: 1px solid var(--green-gay);
    z-index: 0;
  }

  .invalidBg {
    background: radial-gradient(100% 100% at 50% 100%, #FDE9EC 0%, rgba(253, 233, 236, 0.25) 100%);
    border-bottom: 1px solid var(--red-4);
    z-index: 0;
  }

  .label {
    position: absolute;
    left: 16px;
    top: 0;
    z-index: 5;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    color: var(--grey-1);
    transform: scale(1);
    transform-origin: left top;
    transition: .25s transform ease-in-out;
    pointer-events: none;

    @include media("<tablet-lg") {
      font-size: 16px;
      line-height: 16px;
      transform: scale(1) translateY(15px);
      left: 8px;
    }
  }

  @include media("<tablet-lg") {
    margin-bottom: 12px;

    .fieldIcon {
      width: 20px;
      height: 20px;
      bottom: 14px;
      right: 12px;
    }
  }
}

.field {
  position: relative;
  height: 72px;
  background: transparent;
  border: 0;
  outline: none;
  width: 100%;
  padding: 36px 16px 8px;
  font-weight: 700;
  font-size: 20px;
  z-index: 4;

  &:disabled {
    color: var(--grey-1);

    & ~ .label {
      color: var(--grey-7);
    }
  }

  &:not(:focus):not(.invalid):not(:disabled):hover {
    & ~ .bg {
      opacity: 0;
    }

    & ~ .hoverBg {
      opacity: 1;
    }
  }

  &:not(.invalid):focus {
    & ~ .bg {
      opacity: 0;
    }

    & ~ .focusBg {
      opacity: 1;
    }

  }

  &:not(.textarea):not([value=""]), {
    & ~ .label {
      transform: scale(0.80);
      @include media("<tablet-lg") {
        transform: scale(0.80) translateY(6px);
      }
    }
  }

  &:focus {
    & ~ .label {
      transform: scale(0.80);
      @include media("<tablet-lg") {
        transform: scale(0.80) translateY(6px);
      }
    }
  }

  &:not(:disabled).invalid {
    & ~ .bg {
      opacity: 0;
    }

    & ~ .invalidBg {
      opacity: 1;
    }
  }

  &.textarea {
    display: block;
    height: 300px;
    resize: none;
    line-height: 28px;

    &:not(:empty) {
      & ~ .label {
        transform: scale(0.80) translateY(6px);
      }
    }
  }

  @include media("<tablet-lg") {
    height: 48px;
    padding: 16px 8px 0;
    font-size: 16px;

    &.textarea {
      height: 216px;
    }
  }
}

.submit {
  margin-top: auto;
}

.tooltip {
  position: absolute;
  display: flex;
  align-items: center;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--red-3);
  white-space: nowrap;
  border-radius: 8px;
  padding: 4px 8px;
  min-height: 32px;
  opacity: 0;
  pointer-events: none;
  transition: .25s opacity ease-in-out;
  @include media("<phone-lg") {
    width: max-content;
    white-space: normal;
    max-width: 200px;
  }

  &:after {
    position: absolute;
    content: "";
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid var(--red-3);
  }
}
