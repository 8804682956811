@import "../../../../../styles/media-mixin";

.storiesModalWrapper {
  width: 100%;
  height: 832px;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.storiesModalTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  color: var(--white);
  text-align: center;
  padding: 20px;

  @include media("<phone-lg") {
    max-width: 220px;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }
}

.closeIconWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-1);
  border: 1px solid var(--dark-2);
  color: var(--white);
  cursor: pointer;
  transition: 0.25s background-color ease-in-out;

  &:hover {
    background-color: var(--dark-2);
  }
}

.storiesModalItemsWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  min-height: max-content;
  overflow-x: auto;
  opacity: 0;
  transition: 0.25s opacity ease-in-out;
  gap: 8px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.showStories {
  opacity: 1;
}

//.storiesModalItem {
//  position: relative;
//  min-width: 324px;
//  margin: 0 -24px;
//  will-change: transform, margin;
//  transition: 0.15s transform ease-in-out, 0.25s margin ease-in-out;
//  transform: scale(0.85);
//  border-radius: 4px;
//
//  &:after {
//    content: "";
//    will-change: bottom;
//    position: absolute;
//    top: 0;
//    left: 0;
//    bottom: 0;
//    right: 0;
//    background-color: var(--dark-1-50);
//    transition: 0.25s bottom ease-in-out;
//    pointer-events: none;
//  }
//}

//.transparentCover {
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  cursor: pointer;
//}

//.active {
//  transform: scale(1);
//  margin: 0 24px;
//
//  &:after {
//    bottom: unset;
//  }
//
//  &:hover {
//    .leftArrow {
//      opacity: 1;
//      transform: translate(-50%, -50%) scale(1);
//    }
//
//    .rightArrow {
//      opacity: 1;
//      transform: translate(50%, -50%) scale(1);
//    }
//  }
//}

//.leftArrow,
//.rightArrow {
//  width: 56px;
//  height: 56px;
//  position: absolute;
//  top: 50%;
//  border-radius: 50%;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  background-color: var(--dark-1);
//  border: 1px solid var(--dark-2);
//  color: var(--grey-4);
//  cursor: pointer;
//  transform: scale(0);
//  opacity: 0;
//  z-index: 2;
//  will-change: background-color, color, opacity, transform;
//  transition: 0.25s background-color ease-in-out, 0.25s color ease-in-out, 0.25s opacity ease-in-out, 0.1s transform ease-in-out;
//
//  &:hover {
//    background-color: var(--dark-2);
//    color: var(--white);
//  }
//}
//
//.leftArrow {
//  left: 0;
//  transform: translate(-50%, -50%) scale(0);
//}
//
//.rightArrow {
//  right: 0;
//  transform: translate(50%, -50%) scale(0);
//}

.storiesModalActionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 24px;

}

.storiesModalButton {
  width: 320px;
  height: 56px;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-4);

  &:hover {
    color: var(--green-2);
  }

  @include media("<phone-lg") {
    width: 180px;
  }
}

.arrowMobile {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 4px;
  color: var(--grey-4);
  border: none;
  cursor: pointer;
  transition: 0.25s color ease-in-out;

  &:hover {
    color: var(--green-2);
  }

}

//.iframe {
//  border-radius: 4px;
//  width: calc(576px * 720 / 1280);
//  height: 576px;
//}

//.videoImageWrapper {
//  overflow: hidden;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  //height: 100%;
//  border-radius: 4px;
//}
//
//.videoImage {
//  height: 576px;
//  width: auto;
//  object-fit: cover;
//}