.menu {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: 0.15s opacity ease-in-out;
  z-index: var(--select-menu-z-index);
}

.menuShow {
  pointer-events: auto;
  opacity: 1;
}
