:root {
  --white: #FFFFFF;
  --white-08: #FFFFFF08;
  // ----- //
  --black: #000000;
  --black-4: #0000000A;
  --black-12: #0000001F;
  // ----- //
  --dark-1: #151922;
  --dark-1-88: #151922E0;
  --dark-1-50: #15192250;
  --dark-2: #30343C;
  --dark-2-80: #30343C80;
  --dark-3: #31353D;
  --dark-4: #2D3443;
  --dark-5: #33363E;
  --dark-6: #1C2029;
  --dark-7: #232731;
  // ----- //
  --light-1: #F6F6F6;
  --light-1-12: #F6F6F612;
  --light-2: #E3EBF2;
  --light-3: #DAF0E9;
  --light-4: #E3FFF5;
  --light-5: #D9D9D9;
  --light-6: #D3E2F0;
  // ----- //
  --grey-1: #7282A6;
  --grey-2: #D1D9ED;
  --grey-3: #C3D4E3;
  --grey-4: #8A92A3;
  --grey-5: #E1E7F9;
  --grey-6: #8A91A8;
  --grey-7: #A5AFC6;
  --grey-8: #677596;
  --grey-9: #546393;
  --grey-10: #313746;
  --grey-11: #D6DEEF;
  // ----- //
  --green-1: #B7FF7E;
  --green-2: #3DFD95;
  --green-2-hover: #36E486;
  --green-2-10: #3DFD9510;
  --green-2-25: #3DFD9540;
  --green-2-70: #3DFD9570;
  --green-3: #8FFE68;
  --green-4: #28343A;
  --green-5: #0C6A48;
  --green-6: #0F704D;
  --green-7: #38614B;
  --green-gay: #3DFDB8; // ***** 😁 ***** //
  --dark-green: #8AA3A1;
  // ----- //
  --red-1: #FF6B6B;
  --red-2: #FF7171;
  --red-2-32: #FF717132;
  --red-3: #fee4e8;
  --red-4: #FD3D5D;
  --red-5: #EF4335;
  // ----- //
  --blue-1: #62FFE3;
  --blue-2: #61BDFF;
  --blue-3: #1E43FF;
  --blue-4: #52F5FF;
  --blue-5: #1897DA;
  --blue-6: #3D6CFF;
  // ----- //
  --pink-1: #FFA1CE;
  --pink-2: #FF68C5;
  // ----- //
  --purple-1: #FC61FF;
  // ----- //
  --orange-1: #FF701E
}
